import {
  DataLoadingFallback,
  PageMeta,
  Text,
  Heading,
  Box,
  Stack,
  Inline,
  SearchIcon,
  CancelIcon,
  Button,
  PlusIcon,
  WhatsAppFilledIcon,
  Circle,
  ArrowRightIcon,
  SearchSelect,
  UsersFilledIcon,
  BookIcon,
  FormatDistanceTime,
  PencilOutlinedIcon,
  CopyIcon,
  UserAddIcon,
  getButtonClassName,
  Menu,
  MenuButton,
  FiltersIcon,
  MenuList,
  MenuItemHeader,
  MenuItem,
  CheckIcon,
  NoCashbooksFoundIcon,
  UsersOutlinedIcon,
  PlayFilledIcon,
  MoveIcon,
  AddNewEntryIcon,
  PlayVectorIcon,
  BooksVectorIcon,
  InformationCircleFilledIcon,
  BooksInRedVectorIcon,
  SpinnerIcon,
  Tooltip as CBToolTip,
  AddEmailVectorIcon,
  VerifyEmailVectorIcon,
  CBButton,
} from "@cashbook/web-components"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import {
  BUSINESS_PERMISSIONS,
  checkIfTeamMemberCan,
  getBusinessRoleDetails,
  TBusiness,
  T_AVAILABLE_BUSINESS_ROLES,
  useBusiness,
  useBusinesses,
} from "@cashbook/data-store/businesses"
import { useParams, useNavigate, Link } from "react-router-dom"
import { SuspenseWithPerf } from "reactfire"
import ErrorBoundary from "../ErrorBoundary"
import {
  BOOK_PERMISSIONS,
  checkIfMemberCan,
  getRoleDetailsForMember,
  SortBookBy,
  TBook,
  useBooksRecommendations,
  useBusinessBooksSearchForMember,
} from "@cashbook/data-store/books"
import { Steps } from "intro.js-react"

import { useProfile } from "@cashbook/data-store/users"
import { timeStampToDate } from "@cashbook/util-dates"
import { Amount } from "../support/Intl"
import {
  AddBookInDialog,
  DuplicateBookInDialog,
  EditBookInDialog,
} from "../Books"
import { useSyncedStorageState } from "@cashbook/data-store/storage"
import { NotInWeb, ViewPassbookInformationInDialog } from "../support"
import {
  businessRolesColorCodes,
  BusinessUserRoleAndPermissionsForInModal,
  DeletedBusinessInformingInDialog,
  LeftBusinessInformingInDialog,
} from "../Business"
import { trackEvent, TrackingEvents } from "@cashbook/util-tracking"
import config from "../config"
import { WhatsAppContactLink } from "../HelpDocs"
import { SendVerificationMailInDialog } from "../Profile"
import { useUserJourney } from "@cashbook/data-store/payments"
import { isPhoneNumberIndian, isVisitorIndian } from "@cashbook/util-general"

export default function BusinessCashbooksPage() {
  const { businessId } = useParams()
  if (!businessId) return null
  return (
    <ErrorBoundary>
      <SuspenseWithPerf
        fallback={<DataLoadingFallback label="Loading business details..." />}
        traceId="loading_business_details"
      >
        <BusinessCashbooksLayout key={businessId} businessId={businessId} />
      </SuspenseWithPerf>
    </ErrorBoundary>
  )
}

const BOOK_SORT_BY = [
  {
    id: "byLastUpdated",
    label: "Last Updated",
  },
  { id: "byBookName", label: "Name (A to Z)" },
  {
    id: "byBalanceDesc",
    label: "Net Balance (High to Low)",
  },
  {
    id: "byBalanceAsc",
    label: "Net Balance (Low to High)",
  },
  { id: "byLastCreated", label: "Last Created" },
]

function BusinessCashbooksLayout({ businessId }: { businessId: string }) {
  const { business, authTeamMemberDetails, checkIfAuthenticatedTeamMemberCan } =
    useBusiness(businessId)
  const { user } = useProfile()
  const navigate = useNavigate()

  const [cashbooksFilter, setCashbooksFilter] = useSyncedStorageState<{
    [key: string]: SortBookBy
  }>("cashbooksFilter", { [businessId]: "byLastUpdated" })

  const { books, params, setParamValue, handleParamsChange } =
    useBusinessBooksSearchForMember(businessId, user.uid, {
      q: "",
      sortBy: cashbooksFilter[businessId] || "byLastUpdated",
    })
  const canAddBookToBusiness = checkIfAuthenticatedTeamMemberCan(
    BUSINESS_PERMISSIONS.ADD_NEW_BOOK
  )

  const isUserIndian = authTeamMemberDetails.phoneNumber
    ? isPhoneNumberIndian(authTeamMemberDetails.phoneNumber)
    : isVisitorIndian()

  const [isNewUser] = useSyncedStorageState<boolean>("isNewUser", false)
  const [dismissedBanners, setDismissedBanners] = useSyncedStorageState<
    string[]
  >("dismissedBanners", [])

  const showBookNudgeBanner: boolean | undefined = useMemo(() => {
    return dismissedBanners.includes("showBookNudgeBanner")
  }, [dismissedBanners])

  const closeShowBookNudgeBanner = useCallback(() => {
    setDismissedBanners(
      dismissedBanners.includes("showBookNudgeBanner")
        ? [...dismissedBanners]
        : [...dismissedBanners, "showBookNudgeBanner"]
    )
  }, [dismissedBanners, setDismissedBanners])

  const debouncedCloseShowBookNudgeBanner = debounce(
    closeShowBookNudgeBanner,
    500
  )

  useUserJourney(businessId, authTeamMemberDetails.role.id)

  const sortBookByLabel = useMemo(() => {
    return params.sortBy === "byLastUpdated"
      ? "Last Updated"
      : params.sortBy === "byBookName"
      ? "Name (A to Z)"
      : params.sortBy === "byLastCreated"
      ? "Last Created"
      : params.sortBy === "byBalanceDesc"
      ? "Net Balance (High to Low)"
      : params.sortBy === "byBalanceAsc"
      ? "Net Balance (Low to High)"
      : ""
  }, [params.sortBy])

  const [emailAddedAndVerified, setEmailAddedAndVerified] = useState<boolean>(
    user?.emailVerified || false
  )

  const showEmailBanner: boolean = useMemo(() => {
    if (emailAddedAndVerified) return false
    return true
  }, [emailAddedAndVerified])

  const userEmailRef = useRef<string | undefined | null>(user.email)

  return (
    <>
      <PageMeta>
        <title>{business.name}</title>
      </PageMeta>
      <Box
        key={businessId}
        bgColor="white"
        overflowY="hidden"
        paddingBottom="12"
        className="min-h-screen sm:min-h-0"
      >
        <Stack gap="8">
          <Box>
            <Box
              as="header"
              paddingY="4"
              paddingX="8"
              borderBottomWidth="1"
              borderColor="gray100"
            >
              <Inline alignItems="center" justifyContent="between">
                <Heading as="h2" fontSize="lg" fontWeight="semibold">
                  {business.name}
                </Heading>
                <Link
                  className={getButtonClassName({ minWidth: "auto" })}
                  to={`/businesses/${businessId}/business-settings/team`}
                >
                  <Box>
                    <UsersOutlinedIcon />
                  </Box>
                  <Text className="hidden lg:block">Business Team</Text>
                </Link>
              </Inline>
            </Box>
            {canAddBookToBusiness && (
              <Box
                paddingX="3"
                paddingY="4"
                bgColor="gray900"
                className="lg:hidden"
              >
                <AddBookInDialog
                  ownerId={business.ownerId}
                  businessId={businessId}
                  userRole={authTeamMemberDetails.role.id}
                >
                  {({ add }) => (
                    <CBToolTip event="onHover" content="Create Book">
                      <Inline
                        as="button"
                        onClick={add}
                        padding="1"
                        alignItems="center"
                        gap="4"
                        fontWeight="semibold"
                        rounded="md"
                        width="full"
                        className="bg-blue-900 bg-opacity-20"
                        color="white"
                      >
                        <Stack
                          as="span"
                          size="8"
                          alignItems="center"
                          justifyContent="center"
                          bgColor="blue900"
                          rounded="md"
                        >
                          <PlusIcon size="6" />
                        </Stack>
                        <Text as="span">Add New Book</Text>
                      </Inline>
                    </CBToolTip>
                  )}
                </AddBookInDialog>
              </Box>
            )}
            <Box width="full">
              <BusinessRoleUpdateBanner
                businessId={businessId}
                role={authTeamMemberDetails.role.id}
                isWalletIssued={authTeamMemberDetails.can_enable_wallet}
              />
            </Box>
          </Box>
          <NewUpdateBannerPrioritized isNewUser={isNewUser} />
          <Inline paddingX="8" width="full" gap="8" justifyContent="between">
            <Box className="w-full lg:w-3/4">
              <Stack gap="8">
                <Inline gap="6" alignItems="center">
                  <Box className="flex relative bg-opacity-20 rounded focus-within:border-blue-900 focus-within:ring-1 ring-blue-900 h-10 pr-2 items-stretch gap-2 max-w-lg w-full border">
                    <input
                      type="search"
                      name="q"
                      placeholder="Search by book name..."
                      value={params.q}
                      onChange={handleParamsChange}
                      className="bg-transparent outline-none flex-1 pl-4 placeholder:gray-500"
                    />
                    <button
                      type="button"
                      className="flex items-center justify-center text-gray-500"
                      onClick={() =>
                        setParamValue("q", params.q ? "" : params.q)
                      }
                    >
                      {params.q ? <CancelIcon /> : <SearchIcon />}
                    </button>
                  </Box>
                  <Box
                    width={{ md: "auto", lg: "full" }}
                    className="hidden lg:block"
                    alignSelf="stretch"
                  >
                    <SearchSelect
                      searchDisabled
                      height={"10"}
                      hasValue={!params.sortBy}
                      label={`Sort By :  ${sortBookByLabel}`}
                      onChange={(option) => {
                        if (!option) {
                          setCashbooksFilter({
                            ...cashbooksFilter,
                            [businessId]: "byLastUpdated",
                          })
                          return setParamValue("sortBy", "byLastUpdated")
                        }
                        setCashbooksFilter({
                          ...cashbooksFilter,
                          [businessId]: option?.id as SortBookBy,
                        })
                        setParamValue("sortBy", option?.id)
                      }}
                      value={`${params.sortBy}`}
                      options={BOOK_SORT_BY}
                    />
                  </Box>
                  <Box className="block lg:hidden">
                    <Menu>
                      <MenuButton inline>
                        <Inline
                          borderWidth="1"
                          height="10"
                          width="10"
                          rounded="md"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <FiltersIcon color="gray500" />
                        </Inline>
                      </MenuButton>
                      <MenuList align="bottom-right">
                        <MenuItemHeader className=" w-60">
                          <Inline>
                            <Text fontWeight="semibold">Sort Books by</Text>
                          </Inline>
                        </MenuItemHeader>
                        <MenuItem
                          action="update_books_by_last_updated"
                          onClick={() =>
                            setParamValue("sortBy", "byLastUpdated")
                          }
                        >
                          <Inline alignItems="center" gap="2">
                            <CheckIcon
                              color="green500"
                              visibility={
                                params.sortBy === "byLastUpdated"
                                  ? "visible"
                                  : "hidden"
                              }
                            />
                            Last Updated
                          </Inline>
                        </MenuItem>
                        <MenuItem
                          action="update_books_by_name"
                          onClick={() => setParamValue("sortBy", "byBookName")}
                        >
                          <Inline alignItems="center" gap="2">
                            <CheckIcon
                              color="green500"
                              visibility={
                                params.sortBy === "byBookName"
                                  ? "visible"
                                  : "hidden"
                              }
                            />
                            Name (A to Z)
                          </Inline>
                        </MenuItem>
                        <MenuItem
                          action="update_books_by_created"
                          onClick={() =>
                            setParamValue("sortBy", "byLastCreated")
                          }
                        >
                          <Inline alignItems="center" gap="2">
                            <CheckIcon
                              color="green500"
                              visibility={
                                params.sortBy === "byLastCreated"
                                  ? "visible"
                                  : "hidden"
                              }
                            />
                            Last Created
                          </Inline>
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Box>
                </Inline>
                <ErrorBoundary>
                  <SuspenseWithPerf
                    fallback={
                      <DataLoadingFallback label="Loading books in this business..." />
                    }
                    traceId="loading_business_details"
                  >
                    {books.length ? (
                      <Stack as="ul">
                        {books.map((book, index) => {
                          return (
                            <DashboardBookView
                              key={book.id}
                              book={book}
                              isFirstBook={index === 0}
                              memberId={user.uid}
                              onBookClick={() =>
                                navigate(`${book.id}/transactions`)
                              }
                              showBookNudgeBanner={
                                isNewUser && !showBookNudgeBanner
                              }
                              onCloseShowBookNudgeBanner={
                                debouncedCloseShowBookNudgeBanner
                              }
                            />
                          )
                        })}
                        <BookListFooter
                          isNewUser={isNewUser}
                          business={business}
                          books={books}
                        />
                      </Stack>
                    ) : params.q?.length ? (
                      <Stack
                        width="full"
                        gap="8"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Stack
                          width="full"
                          maxWidth="sm"
                          gap="6"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Circle size="16">
                            <BookIcon />
                          </Circle>
                          <Stack gap="2" textAlign="center">
                            <Heading as="h2" fontSize="lg">
                              No books found with this name!
                            </Heading>
                            <Text color="gray500">
                              Check spelling or try some other search term
                            </Text>
                          </Stack>
                          <Box
                            paddingTop="2"
                            onClick={() => setParamValue("q", "")}
                          >
                            <Button level="primary" size="lg">
                              <Box>
                                <CancelIcon />
                              </Box>
                              Clear Search
                            </Button>
                          </Box>
                        </Stack>
                        {business.ownerId === user.uid ? (
                          <CannotFindBooks />
                        ) : null}
                      </Stack>
                    ) : (
                      <HandleNoBooksForMembers
                        isNewUser={isNewUser}
                        ownerId={business.ownerId}
                        businessId={businessId}
                        role={authTeamMemberDetails.role.id}
                      />
                    )}
                  </SuspenseWithPerf>
                </ErrorBoundary>
              </Stack>
            </Box>
            <Stack gap="8" className="w-1/5 hidden lg:flex">
              {canAddBookToBusiness && (
                <AddBookInDialog
                  ownerId={business.ownerId}
                  businessId={businessId}
                  userRole={authTeamMemberDetails.role.id}
                >
                  {({ add }) => (
                    <Button level="primary" minWidth="auto" onClick={add}>
                      <CBToolTip event="onHover" content="Create Book">
                        <Inline
                          alignItems="center"
                          gap="2"
                          className="hidden lg:flex"
                        >
                          <Box>
                            <PlusIcon />
                          </Box>
                          <Text>Add New Book</Text>
                        </Inline>
                      </CBToolTip>
                    </Button>
                  )}
                </AddBookInDialog>
              )}
              {showEmailBanner && (
                <Box
                  borderWidth="1"
                  rounded="lg"
                  padding="4"
                  borderColor={
                    userEmailRef.current?.length
                      ? "borderWarningLow"
                      : "borderSuccessLow"
                  }
                  backgroundColor={
                    userEmailRef.current?.length
                      ? "surfaceWarningLowest"
                      : "surfaceSuccessLowest"
                  }
                >
                  <Stack gap="4">
                    {userEmailRef.current?.length ? (
                      <VerifyEmailVectorIcon size="9" />
                    ) : (
                      <AddEmailVectorIcon size="9" />
                    )}
                    <Stack gap="2">
                      <Text fontSize="s4" fontWeight="semibold">
                        {userEmailRef.current?.length
                          ? "Verify email ID"
                          : "Login via Email ID"}
                      </Text>
                      <Text fontSize="c2" color="textMedium">
                        Verify email to login to{" "}
                        {!isUserIndian ? `mobile app & ` : ""} desktop
                      </Text>
                    </Stack>
                    <SendVerificationMailInDialog
                      onSuccess={() => {
                        setEmailAddedAndVerified(true)
                      }}
                    >
                      {({ openModal }) => (
                        <Box>
                          <CBButton level="primary" onClick={openModal}>
                            {userEmailRef.current?.length ? "Verify" : "Add"}{" "}
                            Email
                          </CBButton>
                        </Box>
                      )}
                    </SendVerificationMailInDialog>
                  </Stack>
                </Box>
              )}
              {isUserIndian ? (
                <Stack
                  gap="4"
                  padding="4"
                  rounded="lg"
                  borderWidth="1"
                  borderColor="borderOutline"
                >
                  <Circle>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M4 4H20C21.11 4 22 4.89 22 6V8H2.00833L2.01 6C2.01 4.89 2.89 4 4 4ZM2 18L2.00667 10H22V18C22 19.11 21.11 20 20 20H4C2.89 20 2 19.11 2 18ZM5.5 13C4.67157 13 4 13.6716 4 14.5C4 15.3284 4.67157 16 5.5 16C6.32843 16 7 15.3284 7 14.5C7 13.6716 6.32843 13 5.5 13Z"
                        fill="#4863D4"
                      />
                    </svg>
                  </Circle>
                  <Stack gap="3">
                    <Stack gap="2">
                      <Text fontSize="s4">Tried Passbook?</Text>
                      <Text fontSize="c2" color="textMedium">
                        Automatically get all online transactions at one place.
                      </Text>
                    </Stack>
                    <ViewPassbookInformationInDialog>
                      {({ open }) => (
                        <Box>
                          <Button
                            inline
                            onClick={() => {
                              open()
                              trackEvent(
                                TrackingEvents.PASSBOOK_INTRODUCTION_BANNER_CLICKED
                              )
                            }}
                          >
                            Know More <ArrowRightIcon />
                          </Button>
                        </Box>
                      )}
                    </ViewPassbookInformationInDialog>
                  </Stack>
                </Stack>
              ) : null}
              <Box
                borderWidth="1"
                rounded="lg"
                padding="4"
                borderColor="borderOutline"
              >
                <Stack gap="4">
                  <Circle backgroundColor="green100">
                    <WhatsAppFilledIcon />
                  </Circle>
                  <Stack gap="2">
                    <Text fontWeight="semibold">
                      Need help in business setup?
                    </Text>
                    <Text color="gray500">Our support team will help you</Text>
                  </Stack>
                  <WhatsAppContactLink
                    text={`Need help in business setup? ${config.appTitle} Web ${config.appVersion}`}
                  >
                    {(props) => (
                      <a
                        {...props}
                        className={getButtonClassName({
                          level: "tertiary",
                          align: "left",
                          inline: true,
                        })}
                        onClick={() => {
                          trackEvent(
                            TrackingEvents.NEED_HELP_IN_BUSINESS_SETUP_CLICKED
                          )
                        }}
                      >
                        Contact Us <ArrowRightIcon />
                      </a>
                    )}
                  </WhatsAppContactLink>
                </Stack>
              </Box>
            </Stack>
          </Inline>
        </Stack>
      </Box>
      <LeftBusinessInformingInDialog />
      <DeletedBusinessInformingInDialog />
    </>
  )
}

const steps = [
  {
    element: "#book_0",
    intro: <Tooltip />,
    position: "top",
    tooltipPosition: "center",
    tooltipClass: "myTooltipClass",
    highlightClass: "myHighlightClass",
  },
]

function Tooltip() {
  return (
    <Box>
      <Inline gap="4" alignItems="start">
        <Box paddingTop="2">
          <AddNewEntryIcon size="6" />
        </Box>
        <Stack gap="2">
          <Heading as="h3" fontSize="md">
            Click to add your first entry to this book
          </Heading>
          <Text fontSize="sm">
            Example - Expenses, Purchase, sale or income
          </Text>
        </Stack>
      </Inline>
    </Box>
  )
}

function DashboardBookView({
  book,
  memberId,
  isFirstBook,
  showBookNudgeBanner,
  onBookClick,
  onCloseShowBookNudgeBanner,
}: {
  book: TBook
  memberId: string
  isFirstBook?: boolean
  showBookNudgeBanner: boolean
  onBookClick: () => void
  onCloseShowBookNudgeBanner: () => void
}) {
  const closingBalance = (book?.totalCashIn || 0) - (book?.totalCashOut || 0)
  const { id } = getRoleDetailsForMember(book, memberId)
  const canViewNetBalance = checkIfMemberCan(
    book,
    memberId,
    BOOK_PERMISSIONS.VIEW_NET_BALANCE
  )
  const canEditBook = checkIfMemberCan(
    book,
    memberId,
    BOOK_PERMISSIONS.EDIT_BOOK
  )
  const canDuplicateBook = checkIfMemberCan(
    book,
    memberId,
    BOOK_PERMISSIONS.DUPLICATE_BOOK
  )
  const canAddMembersInBook = checkIfMemberCan(
    book,
    memberId,
    BOOK_PERMISSIONS.ADD_MEMBER
  )
  const canMoveBook = checkIfMemberCan(
    book,
    memberId,
    BOOK_PERMISSIONS.MOVE_BOOK
  )

  return (
    <Inline
      id={`book_${isFirstBook ? 0 : "1"}`}
      cursor="pointer"
      alignItems="center"
      className="hover:bg-gray-100 has-hover-child group"
      borderTopWidth={isFirstBook ? "0" : "1"}
      onClick={onBookClick}
      paddingY="4"
    >
      <Steps
        enabled={showBookNudgeBanner}
        steps={steps}
        initialStep={0}
        options={{ showButtons: false }}
        onExit={onCloseShowBookNudgeBanner}
      />
      <Box paddingX="3" className="whitespace-pre">
        <Circle size="8">
          {book.sharedWith.length > 1 ||
          (book.pendingInvitationsCount && book.pendingInvitationsCount > 0) ? (
            <UsersFilledIcon size="5" />
          ) : (
            <BookIcon size="5" />
          )}
        </Circle>
      </Box>
      <Box paddingX="3" className="whitespace-pre w-full">
        <Stack gap="2">
          <Text
            fontSize="md"
            fontWeight="semibold"
            className="w-5/6"
            style={{ whiteSpace: "initial" }}
          >
            {book.name}
          </Text>
          <Inline gap="1" fontSize="sm" color="gray500">
            {book.sharedWith.length > 1 ? (
              <Text>{book.sharedWith.length} members</Text>
            ) : null}
            {book.sharedWith.length > 1 ? <Text>•</Text> : null}
            {book.updatedAt ? (
              <Inline gap="1">
                Updated
                <FormatDistanceTime date={timeStampToDate(book.updatedAt)} />
              </Inline>
            ) : book.createdAt ? (
              <Inline gap="1">
                Created
                <FormatDistanceTime date={timeStampToDate(book.createdAt)} />
              </Inline>
            ) : null}
          </Inline>
        </Stack>
      </Box>
      {id === "editor" && book.preferences?.hideEntriesByOthers
        ? null
        : canViewNetBalance && (
            <Box paddingX="3" className="whitespace-pre align-middle">
              <Inline alignItems="center">
                <Amount
                  amount={closingBalance}
                  color={closingBalance < 0 ? "red900" : "green900"}
                  fontWeight="semibold"
                />
              </Inline>
            </Box>
          )}
      <Box
        paddingX="3"
        textAlign="right"
        position="relative"
        className="align-middle"
      >
        <Inline
          gap="4"
          position="relative"
          zIndex="10"
          height="full"
          alignItems="center"
          className="hidden group-hover:flex"
          onClick={(e: React.SyntheticEvent) => {
            e.stopPropagation()
          }}
        >
          {canEditBook && (
            <EditBookInDialog bookId={book.id}>
              {({ edit }) => (
                <Button
                  inline
                  onClick={edit}
                  size="sm"
                  className={`border-transparent text-blue-900`}
                >
                  <CBToolTip event="onHover" content="Rename Book">
                    <PencilOutlinedIcon />
                  </CBToolTip>
                </Button>
              )}
            </EditBookInDialog>
          )}
          {canDuplicateBook && (
            <DuplicateBookInDialog
              book={book}
              bookName={book.name}
              onSuccess={() => {
                return
              }}
            >
              {({ duplicate }) => (
                <Button
                  inline
                  onClick={duplicate}
                  size="sm"
                  className="border-transparent text-blue-900"
                >
                  <CBToolTip event="onHover" content="Duplicate Book">
                    <CopyIcon />
                  </CBToolTip>
                </Button>
              )}
            </DuplicateBookInDialog>
          )}
          {canAddMembersInBook && (
            <CBToolTip event="onHover" content="Add members to book">
              <Link
                to={`${book.id}/settings/members`}
                className={getButtonClassName({ size: "sm", inline: true })}
              >
                <UserAddIcon />
              </Link>
            </CBToolTip>
          )}
          {canMoveBook && (
            <NotInWeb>
              {({ onDisplay }) => (
                <Button
                  inline
                  onClick={onDisplay}
                  size="sm"
                  className="border-transparent text-blue-900"
                >
                  <CBToolTip event="onHover" content="Move Book">
                    <MoveIcon color="red900" />
                  </CBToolTip>
                </Button>
              )}
            </NotInWeb>
          )}
        </Inline>
      </Box>
    </Inline>
  )
}

function NewUpdateBannerPrioritized({ isNewUser }: { isNewUser: boolean }) {
  const [dismissedBanners, setDismissedBanners] = useSyncedStorageState<
    string[]
  >("dismissedBanners", [])
  const isDismissedHowToUseCB = dismissedBanners.find(
    (bannerName) => bannerName === "howToUseCashbook"
  )
  if (isNewUser && !isDismissedHowToUseCB) {
    return (
      <HowToUseCashbookBanner
        onClose={() => {
          setDismissedBanners([...dismissedBanners, "howToUseCashbook"])
        }}
      />
    )
  }
  return null
}

function HowToUseCashbookBanner({ onClose }: { onClose: () => void }) {
  return (
    <Box
      backgroundColor="blue100"
      marginX="8"
      paddingX="6"
      paddingY="3"
      rounded="md"
    >
      <Inline alignItems="center" justifyContent="between">
        <Inline gap="6" alignItems="center">
          <Box>
            <PlayVectorIcon size="12" />
          </Box>
          <Stack gap="2">
            <Heading as="h5">How to use {config.appTitle}?</Heading>
            <Text fontSize="sm">Learn to use {config.appTitle} in a video</Text>
          </Stack>
        </Inline>
        <Inline gap="6" alignItems="center">
          <a
            href={config.howToUseCB}
            target="_blank"
            rel="noreferrer"
            className={getButtonClassName({ size: "base" })}
          >
            <Heading as="h5" color="blue900">
              Watch Video
            </Heading>
            <Box>
              <PlayFilledIcon />
            </Box>
          </a>
          <Button inline onClick={onClose}>
            <CancelIcon color="black" />
          </Button>
        </Inline>
      </Inline>
    </Box>
  )
}

export function BookListFooter({
  books,
  business,
  isNewUser,
}: {
  books: TBook[]
  business: TBusiness
  isNewUser: boolean
}) {
  const { user } = useProfile()
  const { businesses } = useBusinesses()
  const canAddBook = checkIfTeamMemberCan(
    business,
    user.uid,
    BUSINESS_PERMISSIONS.ADD_NEW_BOOK
  )
  let hasAtLeastOneGroupBook = false
  const useCannotFindBooks = useCallback(() => {
    books.forEach((book) => {
      if (book.sharedWith.length > 1) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        hasAtLeastOneGroupBook = true
        return
      }
    })
    if (
      business.createdByDefault &&
      !isNewUser &&
      hasAtLeastOneGroupBook &&
      businesses.length > 1 &&
      user.uid === business.ownerId
    ) {
      return true
    }
    return false
  }, [business.createdByDefault, isNewUser])

  const shouldAddNewBookRecommendations = useCallback(() => {
    return canAddBook && books.length >= 1 && books.length <= 6 ? true : false
  }, [canAddBook, books])

  const existingBookNames = useMemo(() => {
    return books.map((book) => book.name)
  }, [books])

  return (
    <Box paddingY="4">
      {useCannotFindBooks() ? (
        <CannotFindBooks />
      ) : shouldAddNewBookRecommendations() ? (
        <AddNewBookRecommendations
          ownerId={business.ownerId}
          businessId={business.id}
          existingBooks={existingBookNames}
        />
      ) : null}
    </Box>
  )
}

export function CannotFindBooks() {
  return (
    <Box className="bg-[#F5F6FA]" rounded="md" padding="4">
      <Inline alignItems="center" gap="4">
        <Box>
          <BooksInRedVectorIcon size="8" />
        </Box>
        <Stack gap="2">
          <Text as="h3" fontSize="md">
            Can't find your books?
          </Text>
          <Text color="gray500" fontSize="sm">
            Check other businesses on the left side panel
          </Text>
        </Stack>
      </Inline>
    </Box>
  )
}

function AddNewBookRecommendations({
  ownerId,
  businessId,
  existingBooks,
}: {
  ownerId: string
  businessId: string
  existingBooks: string[]
}) {
  const { user } = useProfile()
  const [suggestions, setSuggestions] = useState<string[]>([])
  const [isApiCalled, setIsApiCalled] = useState<boolean>(false)
  const getBooksRecommendations = useBooksRecommendations()

  const usedBooksNames: string[] = useMemo(() => {
    return existingBooks.map((book) => book.toLowerCase())
  }, [existingBooks])

  const filteredSuggestions: string[] = useMemo(() => {
    return suggestions.filter(
      (resultItem) => !usedBooksNames.includes(resultItem.toLowerCase())
    )
  }, [suggestions, usedBooksNames])

  const getSuggestions = useCallback(async () => {
    try {
      const results = await getBooksRecommendations()
      setSuggestions(
        results.filter(
          (resultItem) => !usedBooksNames.includes(resultItem.toLowerCase())
        )
      )
    } catch (e) {
      return e
    }
  }, [getBooksRecommendations, usedBooksNames])

  useEffect(() => {
    if (!isApiCalled) {
      setIsApiCalled(true)
      getSuggestions()
    }
  }, [getSuggestions, isApiCalled])

  return (
    <Box borderWidth="1" rounded="md" padding="6">
      <Inline gap="4">
        <Circle backgroundColor="gray100" size="10">
          <BooksVectorIcon size="6" />
        </Circle>
        <Stack gap="1">
          <Text fontSize="md" fontWeight="semibold">
            Add New Book
          </Text>
          <Text fontSize="sm" color="gray500">
            Click to quickly add books for
          </Text>
          <Inline as="ul" gap="2" paddingTop="3" flexWrap="wrap">
            {!filteredSuggestions.length ? (
              <SpinnerIcon />
            ) : (
              filteredSuggestions.slice(0, 4).map((suggestion) => (
                <AddBookInDialog
                  key={suggestion}
                  ownerId={ownerId}
                  businessId={businessId}
                  initialValues={{ name: suggestion }}
                  userRole={user.uid === ownerId ? "owner" : "partner"}
                >
                  {({ add }) => (
                    <Box
                      as="li"
                      paddingY="2"
                      paddingX="4"
                      borderWidth="1"
                      rounded="full"
                      color="blue900"
                      borderColor="blue200"
                      backgroundColor="blue100"
                      cursor="pointer"
                      onClick={add}
                    >
                      <Text>{suggestion}</Text>
                    </Box>
                  )}
                </AddBookInDialog>
              ))
            )}
          </Inline>
        </Stack>
      </Inline>
    </Box>
  )
}

export function HandleNoBooksForMembers({
  role,
  ownerId,
  isNewUser,
  businessId,
}: {
  ownerId: string
  businessId: string
  isNewUser: boolean
  role: T_AVAILABLE_BUSINESS_ROLES
}) {
  const { user } = useProfile()
  const { businesses } = useBusinesses()
  if (isNewUser && role === "partner") {
    return (
      <Stack
        width="full"
        justifyContent="center"
        alignItems="center"
        gap="6"
        paddingY="12"
      >
        <Circle size="16">
          <BooksVectorIcon />
        </Circle>
        <Stack gap="2" textAlign="center">
          <Heading as="h3" fontSize="lg">
            Add your first book to get started
          </Heading>
          <Text color="gray500">
            Setup your business by adding ‘new books’ and ‘team members’
          </Text>
        </Stack>
        <Stack gap="4">
          <AddBookInDialog
            ownerId={ownerId}
            businessId={businessId}
            userRole={user.uid === ownerId ? "owner" : "partner"}
          >
            {({ add }) => (
              <Button level="primary" onClick={add}>
                <Box>
                  <PlusIcon />
                </Box>
                Add first book
              </Button>
            )}
          </AddBookInDialog>
          <Link
            className={getButtonClassName({})}
            to={`/businesses/${businessId}/business-settings/team`}
          >
            <Box>
              <UserAddIcon />
            </Box>
            Add team member
          </Link>
        </Stack>
      </Stack>
    )
  } else if (role !== "staff" && businesses.length === 1) {
    return (
      <Stack
        width="full"
        justifyContent="center"
        alignItems="center"
        gap="6"
        paddingY="12"
      >
        <Stack
          width="full"
          maxWidth="sm"
          gap="6"
          justifyContent="center"
          alignItems="center"
        >
          <Circle size="16">
            <BookIcon />
          </Circle>
          <Stack gap="2" textAlign="center">
            <Heading as="h2" fontSize="lg">
              No books found with this business!
            </Heading>
          </Stack>
          <Box paddingTop="2">
            <AddBookInDialog
              userRole={role}
              ownerId={ownerId}
              businessId={businessId}
            >
              {({ add }) => (
                <CBToolTip event="onHover" content="Create Book">
                  <Button level="primary" size="lg" onClick={add}>
                    <Box>
                      <PlusIcon />
                    </Box>
                    Add New Book
                  </Button>
                </CBToolTip>
              )}
            </AddBookInDialog>
          </Box>
        </Stack>
      </Stack>
    )
  }

  return (
    <Stack
      width="full"
      justifyContent="center"
      alignItems="center"
      gap="6"
      paddingY="24"
    >
      <Box>
        <NoCashbooksFoundIcon size="12" />
      </Box>
      <Stack color="gray500" textAlign="center">
        <Text>We found no books in this business!</Text>
        <Text>
          Select different business on left side panel to check other books
        </Text>
      </Stack>
    </Stack>
  )
}

function BusinessRoleUpdateBanner({
  role,
  businessId,
  isWalletIssued,
}: {
  businessId: string
  role: T_AVAILABLE_BUSINESS_ROLES
  isWalletIssued?: boolean
}) {
  const [roleUpdateBanner, setRoleUpdateBanner] = useSyncedStorageState<{
    [key: string]: boolean
  }>("roleUpdateBanner", {})
  const {
    business: { name },
  } = useBusiness(businessId)
  const { id, title } = getBusinessRoleDetails(role)
  if (roleUpdateBanner[businessId]) return null
  return (
    <Box
      paddingX="4"
      paddingY="2"
      backgroundColor={businessRolesColorCodes[id].backgroundColor}
    >
      <Inline alignItems="center" gap="3" paddingX="4">
        <Box>
          <InformationCircleFilledIcon
            color={businessRolesColorCodes[id].color}
          />
        </Box>
        <Inline gap="2" alignItems="center">
          <Text color="black" as="span" fontSize="sm">
            Your Role:{" "}
            <Text as="span" fontWeight="semibold">
              {title}
            </Text>
          </Text>
          <BusinessUserRoleAndPermissionsForInModal
            role={id}
            businessName={name}
            onClose={() => {
              setRoleUpdateBanner({
                ...roleUpdateBanner,
                [businessId]: true,
              })
            }}
            isWalletIssued={isWalletIssued}
          >
            {({ onDisplay }) => (
              <Button
                inline
                onClick={() => {
                  onDisplay()
                }}
              >
                <Text fontSize="sm" color={businessRolesColorCodes[id].color}>
                  View
                </Text>
              </Button>
            )}
          </BusinessUserRoleAndPermissionsForInModal>
        </Inline>
      </Inline>
    </Box>
  )
}

function debounce<Params extends unknown[]>(
  func: (...args: Params) => unknown,
  timeout: number
): (...args: Params) => void {
  let timer: NodeJS.Timeout
  return (...args: Params) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func(...args)
    }, timeout)
  }
}
