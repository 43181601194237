import {
  PaymentStatus,
  PaymentTransactionTypes,
  PaymentsTransaction,
  transformDate,
  useCopyToCashbook,
  useGetAttachment,
  useTransactionDetails,
  useTransactionAttachment,
} from "@cashbook/data-store/payments"
import {
  Alert,
  ArrowLeftIcon,
  ArrowRightIcon,
  Box,
  Button,
  ButtonLink,
  CBButton,
  CancelFilledIcon,
  CancelIcon,
  CashbookWithNameIcon,
  CheckCircleSolidIcon,
  CheckIcon,
  Circle,
  ClockIcon,
  CopyIcon,
  CopyToClipboard,
  DisabledIcon,
  DocumentDownloadIcon,
  HourGlassEmptyIcon,
  IconProps,
  InformationCircleFilledIcon,
  InformationWarningIcon,
  Inline,
  Modal,
  ModalBody,
  ModalFooter,
  SearchIcon,
  SharedEntriesIcon,
  SkeletonBox,
  SmallArrowHeadUpIcon,
  SpinnerIcon,
  Stack,
  Text,
  Tooltip,
  TransactionDate,
  checkFileSize,
  checkFileTypes,
  formikOnSubmitWithErrorHandling,
  useDownloadHTMLAsImage,
  useOverlayTriggerState,
} from "@cashbook/web-components"
import React, { useCallback, useMemo, useRef, useState } from "react"
import { Amount } from "../support/Intl"
import {
  Avatar,
  GeneralErrorHandler,
  PAYMENTS_ICON_NAMES,
  PaymentsIcons,
} from "."
import { useBusiness } from "@cashbook/data-store/businesses"
import { toast } from "react-hot-toast"
import { Form, Formik } from "formik"
import * as Validator from "yup"
import PoweredByUpi from "./powered_by_upi.webp"
import {
  EntryAttachmentInfo,
  TBook,
  useBooksForBusinessId,
} from "@cashbook/data-store/books"
import { Timestamp } from "firebase/firestore"
import { formatDate, timeStampToDate } from "@cashbook/util-dates"
import config from "../config"
import { logInfo } from "@cashbook/util-logging"
import { userPaymentsProfileStore } from "@cashbook/data-store/storage"
import { Radio } from "../common"
import { pluralize } from "@cashbook/util-general"
import { useNavigate } from "react-router-dom"
import { TrackingEvents, trackEvent } from "@cashbook/util-tracking"
import { FormMultipleFilesField } from "../Transactions/Log"

export function ViewTransactionAttachmentInModal({
  attachmentId,
  children,
}: {
  attachmentId?: number
  children: (props: { open: () => void }) => React.ReactNode
}) {
  const state = useOverlayTriggerState({})
  const { state: status, getAttachment } = useGetAttachment()

  function openModal() {
    state.open()
    if (attachmentId) {
      getAttachment(attachmentId)
    }
  }

  const imgSrc =
    status.status === "success"
      ? status?.data.url?.[0] || status?.data.thumbUrl[0]
      : ""

  return (
    <>
      {children({
        open: openModal,
      })}
      <Modal
        isDismissable
        isOpen={state.isOpen}
        onClose={state.close}
        title="Attachment Preview"
      >
        <ModalBody>
          <Box width="full" className="h-80">
            {status.status === "in_progress" ? (
              <SkeletonBox width="full" height="full" />
            ) : status.status === "success" && imgSrc.length ? (
              <Box paddingBottom="4">
                <img
                  className="rounded-md "
                  src={imgSrc}
                  alt="Transaction Attachment"
                />
              </Box>
            ) : (
              <Box paddingBottom="12">
                <GeneralErrorHandler
                  retryBtnText="Retry"
                  onRetry={() => {
                    if (attachmentId) {
                      getAttachment(attachmentId)
                    }
                  }}
                />
              </Box>
            )}
          </Box>
        </ModalBody>
        {imgSrc.length ? (
          <ModalFooter>
            <ButtonLink
              size="lg"
              href={imgSrc}
              download="file.png"
              target="_blank"
              rel="noopner noreferrer"
              level="primary"
            >
              <DocumentDownloadIcon /> Download Attachment
            </ButtonLink>
          </ModalFooter>
        ) : null}
      </Modal>
    </>
  )
}

type InternalTransactionParty = {
  title: string
  subtext: string
  balance?: number
  icon: "bank" | "upiWallet" | "masterWallet" | "gradientCard"
}

export function TransactionDetailsInModal({
  transaction_type,
  children,
  ...props
}: React.ComponentProps<typeof TransactionDetails> & {
  transaction_type?: PaymentTransactionTypes
  children: (props: { open: () => void }) => React.ReactNode
}) {
  const state = useOverlayTriggerState({})

  const transaction_scope: "internal" | "external" = useMemo(() => {
    return transaction_type === "B2B" ||
      transaction_type === "B2C" ||
      transaction_type === "C2B" ||
      transaction_type === "VIRTUAL_ACCOUNT_CREDIT"
      ? "internal"
      : "external"
  }, [transaction_type])

  const title: string = useMemo(() => {
    return transaction_type === "B2C"
      ? "Added To Wallet"
      : transaction_type === "B2B" ||
        transaction_type === "VIRTUAL_ACCOUNT_CREDIT"
      ? "Added to Master Wallet"
      : transaction_type === "C2B"
      ? "Withdrawn from Wallet"
      : "Transaction Details"
  }, [transaction_type])

  return (
    <>
      {children({
        open: state.open,
      })}
      <Modal
        isDismissable
        placement="right"
        isOpen={state.isOpen}
        onClose={state.close}
        title={title}
      >
        <TransactionDetails {...props} transaction_scope={transaction_scope} />
      </Modal>
    </>
  )
}

function TransactionDetails({
  memberId,
  businessId,
  isBillAttached,
  transactionId,
  npciTransactionId,
  transaction_scope,
  onAttachmentAdded,
  onAttachmentDeleted,
  ...externalProps
}: {
  memberId?: string
  transactionId: string
  npciTransactionId?: string
  isBillAttached?: boolean
  businessId: string
  transaction_scope?: "internal" | "external"
  onAttachmentDeleted?: () => void
  onAttachmentAdded?: () => void
}) {
  const { error, loading, attachments, transaction, retry } =
    useTransactionDetails(
      businessId,
      transactionId,
      npciTransactionId,
      isBillAttached
    )

  return loading ? (
    <>
      <ModalBody>
        <Stack gap="6">
          <SkeletonBox width="full" height="full" className="h-48" />
          <SkeletonBox width="full" height="full" className="h-64" />
        </Stack>
      </ModalBody>
    </>
  ) : error?.message ? (
    <ModalBody>
      <Stack gap="6" height="full" alignItems="center" justifyContent="center">
        <Stack alignItems="center" justifyContent="center">
          <SharedEntriesIcon size="12" />
          <Stack gap="2" justifyContent="center" alignItems="center">
            <Stack justifyContent="center" alignItems="center">
              <Text fontSize="s1">Something Went Wrong!</Text>
              <Text fontSize="b3">
                Your data is absolutely safe and backed up
              </Text>
            </Stack>
            <Text fontSize="c2">{error?.message}</Text>
          </Stack>
        </Stack>
        <Button level="primary" onClick={retry}>
          Retry
        </Button>
      </Stack>
    </ModalBody>
  ) : transaction_scope === "internal" && transaction ? (
    <InternalTransactionDetails
      businessId={businessId}
      memberId={memberId}
      transaction={transaction}
    />
  ) : transaction_scope === "external" && transaction ? (
    <ExternalTransaction
      businessId={businessId}
      transaction={transaction}
      attachments={attachments}
      onAttachmentAdded={onAttachmentAdded}
      onAttachmentDeleted={onAttachmentDeleted}
      {...externalProps}
    />
  ) : null
}

function InternalTransactionDetails({
  memberId,
  businessId,
  transaction,
}: {
  memberId?: string
  businessId: string
  transaction: PaymentsTransaction
}) {
  const {
    amount,
    type,
    timestamp,
    created_at,
    transaction_type,
    balance,
    ext_party_fid,
    created_by,
    payer_meta,
    related,
  } = transaction
  const {
    business: { name },
    authTeamMemberDetails,
    getTeamMemberInfoForId,
  } = useBusiness(businessId)

  const from: InternalTransactionParty = useMemo(() => {
    const member = getTeamMemberInfoForId(ext_party_fid)
    switch (transaction_type) {
      case "B2B":
      case "VIRTUAL_ACCOUNT_CREDIT":
        return {
          title: payer_meta?.name ? payer_meta.name : "XXXX Bank XXXX",
          subtext: payer_meta?.id
            ? `A/c No - ${payer_meta?.id}`
            : "Bank Account",
          icon: "bank",
        }
      case "C2B":
        return {
          title:
            authTeamMemberDetails.id === member?.uid
              ? "Your Wallet"
              : member?.name || "",
          subtext: member?.phoneNumber || "",
          balance: Number(related?.balance) || 0,
          icon: "gradientCard",
        }
      default:
        return {
          title: name,
          subtext: "Master Wallet",
          balance: memberId ? Number(related?.balance || 0) : balance || 0,
          icon: "masterWallet",
        }
    }
  }, [
    getTeamMemberInfoForId,
    ext_party_fid,
    transaction_type,
    payer_meta?.name,
    payer_meta?.id,
    authTeamMemberDetails.id,
    related?.balance,
    name,
    memberId,
    balance,
  ])

  const to: InternalTransactionParty = useMemo(() => {
    if (transaction_type === "B2C") {
      const member = getTeamMemberInfoForId(memberId || ext_party_fid)
      return {
        title:
          member?.id === authTeamMemberDetails.id
            ? "Your Wallet"
            : member?.name
            ? `${member.name}'s Wallet`
            : "",
        subtext: member?.phoneNumber || "",
        balance:
          memberId && balance ? balance : related ? Number(related.balance) : 0,
        icon: "gradientCard",
      }
    }
    return {
      title: name,
      subtext: "Master Wallet",
      balance: balance || 0,
      icon: "masterWallet",
    }
  }, [
    authTeamMemberDetails.id,
    balance,
    ext_party_fid,
    getTeamMemberInfoForId,
    name,
    related,
    transaction_type,
    memberId,
  ])

  const transactionBy = useMemo(() => {
    const member = getTeamMemberInfoForId(created_by)
    if (
      transaction_type !== "B2B" &&
      transaction_type !== "VIRTUAL_ACCOUNT_CREDIT"
    ) {
      return {
        id: member?.id || "",
        name:
          member?.id === authTeamMemberDetails.id ? "You" : member?.name || "",
        phone: member?.phoneNumber || "",
      }
    }
  }, [
    getTeamMemberInfoForId,
    created_by,
    transaction_type,
    authTeamMemberDetails.id,
  ])

  return (
    <>
      <ModalBody>
        <Stack gap="6">
          <Box
            rounded="md"
            borderWidth="1"
            borderTopWidth="4"
            borderColor="borderOutline"
            borderTopColor={
              type === "CREDIT" ? "surfaceCashIn" : "surfaceCashOut"
            }
          >
            <Inline
              borderBottomWidth="1"
              borderColor="borderOutline"
              paddingY="5"
              paddingX="6"
              alignItems="center"
              justifyContent="between"
            >
              <Amount
                amount={Number(amount)}
                currency="inr"
                fontSize="h2"
                type={type === "DEBIT" ? "cash-out" : "cash-in"}
              />
              <TransactionDate
                fontSize="b3"
                showFullDate
                format="dd MMM yyyy, hh:mm a"
                timeStamp={transformDate(timestamp || created_at)}
              />
            </Inline>
            <Stack
              padding="6"
              gap={from.balance ? "10" : "16"}
              position="relative"
            >
              <Inline gap="6">
                <PaymentsIcons size="10" color="iconPrimary" name={from.icon} />
                <Stack gap="2">
                  <Text fontSize="s3" className="line-clamp-2">
                    {from.title}
                  </Text>
                  <Inline gap="4">
                    <Text fontSize="b3" color="textMedium">
                      {from.subtext}
                    </Text>
                  </Inline>

                  {from.balance ? (
                    <Inline gap="2" fontSize="b3" color="textMedium">
                      <Text>Closing Balance: </Text>
                      <Amount currency="inr" amount={from.balance} />
                    </Inline>
                  ) : null}
                </Stack>
              </Inline>
              <Box
                width="10"
                position="absolute"
                display="flex"
                justifyContent="center"
                alignItems="center"
                marginTop="14"
              >
                <svg
                  width="14"
                  height="34"
                  viewBox="0 0 14 34"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.00047 0H6.00047V24.1851H0.0722656L7.00047 34L13.9287 24.1851H8.00047V0Z"
                    fill="#B6C1EE"
                  />
                </svg>
              </Box>
              <Inline gap="6">
                <PaymentsIcons size="10" name={to.icon} />
                <Stack gap="2">
                  <Text fontSize="s3" className="line-clamp-2">
                    {to.title}
                  </Text>
                  <Inline gap="4">
                    <Text fontSize="b3" color="textMedium">
                      {to.subtext}
                    </Text>
                  </Inline>
                  {to.balance ? (
                    <Inline gap="2" fontSize="b3" color="textMedium">
                      <Text>Closing Balance: </Text>
                      <Amount currency="inr" amount={to.balance} />
                    </Inline>
                  ) : null}
                </Stack>
              </Inline>
            </Stack>
          </Box>
          <Box borderWidth="1" rounded="md" borderColor="borderOutline">
            <Box
              paddingY="4"
              paddingX="6"
              borderBottomWidth="1"
              borderColor="borderOutline"
            >
              <Text color="textMedium" fontSize="s3">
                Other Details
              </Text>
            </Box>
            <Stack gap="6" padding="6">
              {transactionBy?.name ? (
                <Stack gap="4">
                  <Text fontSize="b3" color="textMedium">
                    Transaction by
                  </Text>
                  <Inline gap="4" alignItems="center">
                    <Avatar
                      id={transactionBy.id}
                      name={transactionBy.name}
                      size="12"
                      fontSize="h3"
                    />
                    <Stack gap="2">
                      <Text fontSize="s3" className="line-clamp-1">
                        {transactionBy.name}
                      </Text>
                      <Text fontSize="b3" color="textMedium">
                        {transactionBy.phone}
                      </Text>
                    </Stack>
                  </Inline>
                </Stack>
              ) : null}
            </Stack>
          </Box>
        </Stack>
      </ModalBody>
    </>
  )
}

type TransactionIconName = "upi" | "card" | "bank"

const addAttachmentSchema = Validator.object().shape({
  transaction_file: Validator.mixed()
    .nullable()
    .test(
      "type-is-correct",
      "Only image files (png/jpeg/jpg) are supported.",
      checkFileTypes(["image/png", "image/jpeg", "image/jpg"])
    )
    .test(
      "size-not-big",
      "Please select the image that is less then 5 MB in size",
      checkFileSize(5000)
    ),
})

type OTHER_DETAILS_OPTION_TYPE = {
  id: "otherDetails" | "paymentStatus"
  title: string
}

type PartiesInvolvedType = {
  id: string
  name: string
  title?: string
  subtext: string
  icon: TransactionIconName
  partyType?: string
}
function ExternalTransaction({
  transaction,
  attachments,
  businessId,
  onAttachmentAdded,
  onAttachmentDeleted,
}: {
  businessId: string
  attachments?: { [id: string]: EntryAttachmentInfo }
  transaction: PaymentsTransaction
  onAttachmentDeleted?: () => void
  onAttachmentAdded?: () => void
}) {
  const {
    id,
    amount,
    type,
    timestamp,
    created_at,
    party,
    description,
    cust_ref,
    status,
    created_by,
    user_id,
    copied_to_books,
    npci_txn_id,
  } = transaction

  const { business, authTeamMemberDetails, getTeamMemberInfoForId } =
    useBusiness(businessId)
  const { getBookNamesById } = useBooksForBusinessId(businessId)
  const { deleteAttachment, addAttachment } = useTransactionAttachment()

  const [activeDetails, setActiveDetails] = useState<
    "otherDetails" | "paymentStatus"
  >("otherDetails")

  const transactionParty: PartiesInvolvedType = useMemo(() => {
    return {
      id: party?.id || "",
      name: party?.name || "",
      subtext: party?.address || "",
      icon: "upi",
      partyType: party?.entity_type,
    }
  }, [party])

  const authUserProfile = userPaymentsProfileStore.getUserProfile()

  const isCurrentUserPayeeOrPayer: boolean = useMemo(() => {
    if (authUserProfile && authUserProfile?.entityId) {
      return Boolean(authUserProfile.entityId === transaction.entity_id)
    } else if (
      authTeamMemberDetails?.id === user_id ||
      authTeamMemberDetails?.id === created_by
    ) {
      return true
    }
    return false
  }, [
    authTeamMemberDetails?.id,
    authUserProfile,
    created_by,
    transaction.entity_id,
    user_id,
  ])

  const transactionMember: PartiesInvolvedType = useMemo(() => {
    if (isCurrentUserPayeeOrPayer) {
      return {
        id: authTeamMemberDetails?.id,
        name: authTeamMemberDetails.name || "",
        subtext: authUserProfile?.upi?.address || "",
        title: "Your Wallet",
        icon: transactionParty.icon,
      }
    }
    const member = getTeamMemberInfoForId(user_id || "")
    return {
      id: member?.id || "",
      name: member?.name || "",
      subtext: "",
      title: `${member?.name}'s Wallet`,
      icon: transactionParty.icon,
    }
  }, [
    isCurrentUserPayeeOrPayer,
    getTeamMemberInfoForId,
    user_id,
    transactionParty.icon,
    authTeamMemberDetails.id,
    authTeamMemberDetails.name,
    authUserProfile?.upi?.address,
  ])

  const showPaymentStatus: boolean = useMemo(() => {
    if (
      transaction.status === "SUCCESS" ||
      transaction.status === "FAILURE" ||
      transaction.status === "PENDING" ||
      transaction.status === "DEEMED" ||
      transaction.status === "REFUNDED" ||
      transaction.status === "REVERSED"
    ) {
      if (transactionParty.icon === "upi" && id && type === "DEBIT") {
        return true
      }
    }
    return false
  }, [id, transaction.status, transactionParty.icon, type])

  const OPTIONS_FOR_MORE_DETAILS: Array<OTHER_DETAILS_OPTION_TYPE> =
    useMemo(() => {
      const optionForOtherDetails: OTHER_DETAILS_OPTION_TYPE[] = [
        { id: "otherDetails", title: "Other Details" },
      ]
      if (showPaymentStatus) {
        optionForOtherDetails.push({
          id: "paymentStatus",
          title: "Payment Status",
        })
      }
      return optionForOtherDetails
    }, [showPaymentStatus])

  const copiedTo: string | undefined = useMemo(() => {
    if (!copied_to_books) {
      return
    }
    const bookIds = Object.keys(copied_to_books)
    const bookByIds = getBookNamesById()
    let copiedToBooks = ""
    bookIds.forEach((id, i) => {
      if (bookByIds[id]) {
        copiedToBooks =
          copiedToBooks +
          ` ${bookByIds[id]}${i === bookIds.length - 1 ? "" : ","} `
      }
    })
    return copiedToBooks
  }, [copied_to_books, getBookNamesById])

  const { download, status: downloadStatus } = useDownloadHTMLAsImage()

  const toAndFrom = useMemo(() => {
    if (type === "CREDIT") {
      return {
        to: transactionMember,
        from: transactionParty,
      }
    } else {
      return {
        to: transactionParty,
        from: transactionMember,
      }
    }
  }, [transactionMember, transactionParty, type])

  const otherDetailsForReceipt = useMemo(() => {
    const details: { [key: string]: string } = {}
    if (description?.length) {
      details["Remark/Note"] = description
    }
    if (business.name) {
      details["Business Name"] = business.name
    }
    if (npci_txn_id?.length) {
      details["Transaction ID"] = npci_txn_id || ""
    }
    if (cust_ref) {
      details["UPI Reference Number"] = cust_ref
    }
    return details
  }, [business.name, cust_ref, description, npci_txn_id])

  const receiptHtmlRef = useRef<HTMLDivElement>(null)

  const downloadReceipt = useCallback(async () => {
    if (!receiptHtmlRef.current) return
    const fileName = `Invoice ${id} ${formatDate(new Date(), "dd-MM-yyyy")}@${
      config.appTitle
    }`
    try {
      receiptHtmlRef.current.classList.replace("opacity-0", "opacity-1")
      download(receiptHtmlRef.current, fileName).then(() => {
        receiptHtmlRef.current?.classList.replace("opacity-0", "opacity-1")
      })
    } catch (e) {
      const error = e as Error
      toast.error(error.message || "Something Went Wrong!")
      logInfo(
        `Error generating transaction receipt "${error?.message || "Unknown"}"`
      )
    }
  }, [download, id])

  const topBorderColor: React.ComponentProps<typeof Box>["borderTopColor"] =
    useMemo(() => {
      switch (status) {
        case "SUCCESS":
        case "REFUNDED":
          return "borderSuccess"
        case "FAILURE":
        case "REVERSED":
          return "borderError"
        case "EXPIRED":
          return "iconMedium"
        default:
          return "borderWarning"
      }
    }, [status])

  return (
    <>
      <ModalBody>
        <Box position="relative">
          <Box backgroundColor="backgroundLight1">
            <Stack gap="6">
              <Box
                rounded="md"
                borderWidth="1"
                borderTopWidth="4"
                borderTopColor={topBorderColor}
                borderColor="borderOutline"
              >
                <Stack
                  paddingY="5"
                  paddingX="6"
                  gap="4"
                  borderBottomWidth="1"
                  borderColor="borderOutline"
                >
                  {status !== "SUCCESS" &&
                  status !== "REFUNDED" &&
                  status !== "EXPIRED" ? (
                    <PaymentStatusBanner status={status} />
                  ) : null}
                  <Stack gap="2">
                    <Inline justifyContent="between" alignItems="center">
                      <Inline alignItems="center" gap="2">
                        <Amount
                          fontSize="h2"
                          currency="inr"
                          amount={Number(amount || 0)}
                        />
                        {status === "PENDING" || status === "DEEMED" ? (
                          <ClockIcon color="iconWarning" />
                        ) : status === "EXPIRED" ? (
                          <HourGlassEmptyIcon color="iconMedium" />
                        ) : status === "FAILURE" || status === "REVERSED" ? (
                          <CancelFilledIcon color="iconError" />
                        ) : (
                          <SmallArrowHeadUpIcon
                            className={type === "DEBIT" ? "" : "rotate-180"}
                            color={
                              type === "DEBIT" ? "iconCashOut" : "iconCashIn"
                            }
                          />
                        )}
                      </Inline>
                      <TransactionDate
                        fontSize="b3"
                        showFullDate
                        format="dd MMM yyyy, hh:mm a"
                        timeStamp={transformDate(timestamp || created_at)}
                      />
                    </Inline>
                    <Box display="flex" justifyContent="end" alignItems="end">
                      <PaymentStatusTag
                        type={type}
                        status={status}
                        transactionType={transaction.transaction_type}
                      />
                    </Box>
                  </Stack>
                </Stack>
                {transactionParty.id && transactionMember.id ? (
                  <Stack gap="6" padding="6">
                    <Stack gap="2">
                      <Text fontSize="b3">
                        {type === "DEBIT"
                          ? status === "SUCCESS"
                            ? "Paid to"
                            : "To"
                          : "From"}
                      </Text>
                      <Inline gap="3" alignItems="center">
                        <Avatar
                          size="10"
                          id={transactionParty.id}
                          name={transactionParty.name}
                          fontSize="s2"
                          iconSize="6"
                          type={
                            party?.entity_type === "ENTITY"
                              ? "merchant"
                              : "individual"
                          }
                        />
                        <Stack gap="2">
                          <Text fontSize="s4">{transactionParty.name}</Text>
                          {transactionParty.subtext.length &&
                          transactionParty.icon ? (
                            <Inline gap="1" alignItems="center">
                              {transaction.party?.entity_type !== "ENTITY" ? (
                                <PaymentsIcons
                                  size="3"
                                  color="iconMedium"
                                  name={transactionParty.icon}
                                />
                              ) : null}
                              <Text fontSize="c2" color="textMedium">
                                {transactionParty.subtext}
                              </Text>
                            </Inline>
                          ) : null}
                        </Stack>
                      </Inline>
                    </Stack>
                    <Inline
                      rounded="md"
                      padding="4"
                      gap="3"
                      backgroundColor="surfaceNeutralLowest"
                    >
                      <Text fontSize="c2" color="textMedium">
                        {type === "DEBIT" ? "From" : "To"}
                      </Text>
                      <Stack gap="2">
                        <Text>{transactionMember.title}</Text>
                        {transactionMember.icon &&
                        transactionMember.subtext?.length ? (
                          <Inline gap="1" alignItems="center">
                            <PaymentsIcons
                              size="3"
                              color="iconMedium"
                              name={transactionMember.icon}
                            />
                            <Text fontSize="c2" color="textMedium">
                              {transactionMember.subtext}
                            </Text>
                          </Inline>
                        ) : null}
                      </Stack>
                    </Inline>
                  </Stack>
                ) : null}
              </Box>
              <Box borderWidth="1" borderColor="borderOutline" rounded="md">
                <Inline
                  as="ul"
                  gap="2"
                  paddingTop="2"
                  paddingX="6"
                  borderBottomWidth="1"
                  borderColor="borderOutline"
                >
                  {OPTIONS_FOR_MORE_DETAILS.map((option) => (
                    <Box
                      key={option.id}
                      padding="3"
                      cursor="pointer"
                      onClick={() => {
                        setActiveDetails(option.id)
                      }}
                      borderBottomWidth={
                        activeDetails === option.id ? "2" : undefined
                      }
                      borderColor="borderPrimary"
                    >
                      <Text
                        fontSize="s4"
                        color={
                          activeDetails === option.id
                            ? "textPrimary"
                            : "textMedium"
                        }
                      >
                        {option.title}
                      </Text>
                    </Box>
                  ))}
                </Inline>
                <Box padding="6" backgroundColor="backgroundLight2">
                  {activeDetails === "otherDetails" ? (
                    <Stack gap="6">
                      {status === "SUCCESS" ? (
                        <Stack gap="2">
                          <Text fontSize="c2" color="textMedium">
                            Attachment
                          </Text>
                          <Formik
                            initialValues={{
                              bill_file: undefined as
                                | {
                                    file: File
                                    fileName: string
                                    fileType: string
                                  }
                                | undefined,
                              attachments: attachments,
                              operation: undefined,
                              attachmentId: undefined,
                            }}
                            onSubmit={formikOnSubmitWithErrorHandling(
                              async (values, actions) => {
                                if (
                                  values.operation === "delete" &&
                                  values.attachmentId
                                ) {
                                  await deleteAttachment(
                                    Number(values.attachmentId)
                                  )
                                  onAttachmentDeleted?.()
                                  const deletedAttachments = {
                                    ...values.attachments,
                                  }
                                  delete deletedAttachments?.[
                                    values.attachmentId
                                  ]
                                  actions.setFieldValue(
                                    "attachments",
                                    deletedAttachments
                                  )
                                  return
                                }
                                if (values.bill_file) {
                                  const { data } = await addAttachment(
                                    values.bill_file.file,
                                    npci_txn_id
                                  )
                                  if (data && data.id) {
                                    onAttachmentAdded?.()
                                    const updatedAttachments = {
                                      ...values.attachments,
                                    }
                                    updatedAttachments[data.id] = {
                                      url: data.url || "",
                                      thumbUrl: data.thumbUrl || "",
                                      fileName: "",
                                      mimeType: data.mime_type || "image/png",
                                      id: data.id || "",
                                    }
                                    actions.setFieldValue(
                                      "attachments",
                                      updatedAttachments
                                    )
                                  }
                                }
                              }
                            )}
                            validationSchema={addAttachmentSchema}
                          >
                            {({
                              values,
                              isSubmitting,
                              setFieldValue,
                              submitForm,
                            }) => (
                              <Form noValidate>
                                <Box className="max-w-fit" position="relative">
                                  <FormMultipleFilesField
                                    name="bill_file"
                                    id="bill_file"
                                    from="payments"
                                    defaultValues={values.attachments}
                                    loading={isSubmitting}
                                    onSelectionEnd={() => {
                                      setFieldValue("operation", "add")
                                      submitForm()
                                    }}
                                    onRemove={(attachmentId: string) => {
                                      setFieldValue(
                                        "attachmentId",
                                        attachmentId
                                      )
                                      setFieldValue("operation", "delete")
                                      submitForm()
                                    }}
                                  />
                                </Box>
                              </Form>
                            )}
                          </Formik>
                        </Stack>
                      ) : null}

                      {npci_txn_id?.length ? (
                        <Stack gap="2">
                          <Text fontSize="c2" color="textMedium">
                            Transaction ID
                          </Text>
                          <Inline justifyContent="between" alignItems="center">
                            <Text fontSize="b3">{npci_txn_id}</Text>
                            <CopyToClipboard>
                              {({ copied, copy }) => {
                                return copied ? (
                                  <CheckIcon color="iconSuccess" />
                                ) : (
                                  <Tooltip
                                    event="onHover"
                                    content="Copy to clipboard"
                                  >
                                    <CopyIcon
                                      cursor="pointer"
                                      onClick={() => {
                                        copy(npci_txn_id || "")
                                        toast.success(
                                          "Transaction ID copied to clipboard"
                                        )
                                      }}
                                      color="textMedium"
                                    />
                                  </Tooltip>
                                )
                              }}
                            </CopyToClipboard>
                          </Inline>
                        </Stack>
                      ) : null}

                      {cust_ref?.length ? (
                        <Stack gap="2">
                          <Text fontSize="c2" color="textMedium">
                            UPI Reference Number
                          </Text>
                          <Inline justifyContent="between" alignItems="center">
                            <Text fontSize="b3">{cust_ref}</Text>
                            <CopyToClipboard>
                              {({ copied, copy }) => {
                                return copied ? (
                                  <CheckIcon color="iconSuccess" />
                                ) : (
                                  <Tooltip
                                    event="onHover"
                                    content="Copy to clipboard"
                                  >
                                    <CopyIcon
                                      cursor="pointer"
                                      onClick={() => {
                                        copy(cust_ref)
                                        toast.success(
                                          "UPI Reference number copied to clipboard"
                                        )
                                      }}
                                      color="textMedium"
                                    />
                                  </Tooltip>
                                )
                              }}
                            </CopyToClipboard>
                          </Inline>
                        </Stack>
                      ) : null}
                    </Stack>
                  ) : (
                    <PaymentJourney
                      status={status}
                      isReversed={status === "REVERSED"}
                    />
                  )}
                </Box>
              </Box>
              {copiedTo?.length ? (
                <Box borderWidth="1" borderColor="borderOutline" rounded="md">
                  <Box
                    paddingY="3"
                    paddingX="6"
                    borderBottomWidth="1"
                    borderColor="borderOutline"
                  >
                    <Text fontSize="s4" color="textMedium">
                      Copied To
                    </Text>
                  </Box>
                  <Box padding="6" backgroundColor="backgroundLight2">
                    <Text>{copiedTo}</Text>
                  </Box>
                </Box>
              ) : null}
            </Stack>
            {transactionParty.icon === "upi" ? (
              <Stack paddingY="12" alignItems="center" justifyContent="center">
                <Box width="24">
                  <img src={PoweredByUpi} alt="Powered By UPI" />
                </Box>
              </Stack>
            ) : null}
          </Box>
          {status === "SUCCESS" ||
          status === "FAILURE" ||
          status === "REVERSED" ||
          status === "REFUNDED" ? (
            <Box
              position="absolute"
              className="-z-[1]"
              width="full"
              top="0"
              opacity="0"
            >
              <Box ref={receiptHtmlRef}>
                <TransactionReceiptWithDetails
                  type={type}
                  status={status}
                  to={toAndFrom.to}
                  from={toAndFrom.from}
                  amount={Number(amount || 0)}
                  timestamp={transformDate(timestamp || created_at)}
                  otherDetails={otherDetailsForReceipt}
                />
              </Box>
            </Box>
          ) : null}
        </Box>
      </ModalBody>
      {(status === "SUCCESS" ||
        status === "FAILURE" ||
        status === "REVERSED" ||
        status === "REFUNDED") && (
        <ModalFooter>
          {(status === "SUCCESS" || status === "REFUNDED") && (
            <CopyTransactionsToCashbook
              businessId={businessId}
              transactions={[transaction]}
            >
              {({ open }) => (
                <CBButton
                  onClick={() => {
                    open()
                    trackEvent(
                      TrackingEvents.PAYMENTS_COPY_TO_CASHBOOK_INITIATED,
                      {
                        from: "transactionDetails",
                      }
                    )
                  }}
                  level="primary"
                  size="lg"
                  iconPlacement="left"
                >
                  <CopyIcon /> Copy To Cashbook
                </CBButton>
              )}
            </CopyTransactionsToCashbook>
          )}
          <Button
            size="lg"
            onClick={downloadReceipt}
            disabled={downloadStatus === "in_progress"}
          >
            <Box>
              {downloadStatus === "in_progress" ? (
                <SpinnerIcon />
              ) : (
                <DocumentDownloadIcon />
              )}
            </Box>
            Download Receipt
          </Button>
        </ModalFooter>
      )}
    </>
  )
}

type StepInPaymentJourney = {
  title: string
  icon: PAYMENTS_ICON_NAMES
  iconColor: React.ComponentProps<typeof PaymentsIcons>["color"]
}

function getPaymentStatusInSteps(
  isReversed: boolean,
  status: PaymentStatus
): StepInPaymentJourney[] {
  const steps: StepInPaymentJourney[] = [
    {
      title: "Wallet PIN validated and payment started",
      icon: "checkCircle",
      iconColor: "iconSuccess",
    },
  ]
  if (
    status === "SUCCESS" ||
    status === "FAILURE" ||
    status === "REVERSED" ||
    status === "DEEMED"
  ) {
    steps.push({
      title: "Payment deducted from wallet",
      icon: status === "FAILURE" ? "cancelCircle" : "checkCircle",
      iconColor: status === "FAILURE" ? "iconError" : "iconSuccess",
    })
    steps.push({
      title: `Payment credited to recipient’s bank`,
      iconColor:
        status === "SUCCESS"
          ? "iconSuccess"
          : status === "FAILURE" || status === "REVERSED"
          ? "iconError"
          : "iconWarning",
      icon:
        status === "SUCCESS"
          ? "checkCircle"
          : status === "FAILURE" || status === "REVERSED"
          ? "cancelCircle"
          : "iconWarning",
    })
  } else {
    steps.push({
      title: "Payment deducted from wallet",
      icon: "iconWarning",
      iconColor: "iconWarning",
    })
  }
  if (isReversed) {
    steps.push({
      title: "Payment reversed to wallet",
      iconColor: "iconSuccess",
      icon: "checkCircle",
    })
  }
  return steps
}

function PaymentJourney({
  isReversed,
  status,
}: {
  isReversed: boolean
  status: PaymentStatus
}) {
  const steps = getPaymentStatusInSteps(isReversed, status)
  return (
    <Stack gap="6" as="ul" justifyContent="center" position="relative">
      {steps.map((step) => (
        <Inline
          key={step.title}
          as="li"
          alignItems="center"
          gap="2"
          className="z-[1]"
        >
          <Box backgroundColor="white" rounded="full">
            <PaymentsIcons name={step.icon} color={step.iconColor} />
          </Box>
          <Text fontSize="b3">{step.title}</Text>
        </Inline>
      ))}
      <Stack
        height="full"
        width="px"
        backgroundColor="borderOutline"
        position="absolute"
        top="0"
        zIndex="0"
        className="left-3"
      ></Stack>
    </Stack>
  )
}

export function getStatusTitle({
  type,
  status,
  transactionType,
}: {
  status: PaymentStatus
  type: "CREDIT" | "DEBIT"
  transactionType?: PaymentTransactionTypes
}) {
  switch (status) {
    case "PENDING":
    case "DEEMED":
      return "Pending"
    case "REQUESTED":
      return "Unpaid"
    case "FAILURE":
    case "REVERSED":
      return "Failed"
    case "SUCCESS":
      return type === "CREDIT" ? "Received" : "Paid"
    case "REFUNDED":
      return "Refunded"
    case "EXPIRED":
      return "Expired"
    default:
      return ""
  }
}

export function getTextColorForStatus({
  status,
}: {
  status: PaymentStatus
}): React.ComponentProps<typeof Text>["color"] {
  switch (status) {
    case "PENDING":
    case "DEEMED":
      return "textWarning"
    case "FAILURE":
    case "REVERSED":
      return "textError"
    case "SUCCESS":
      return "textSuccess"
    case "REFUNDED":
      return "textSuccess"
    default:
      return "textMedium"
  }
}

export function getHelperIconForStatus({
  status,
  ...props
}: IconProps & {
  status: PaymentStatus
}): React.ReactNode | undefined {
  switch (status) {
    case "PENDING":
    case "DEEMED":
    case "REQUESTED":
      return <ClockIcon {...props} />
    case "REJECTED":
      return <DisabledIcon {...props} />
    case "EXPIRED":
      return <HourGlassEmptyIcon {...props} />
    case "FAILURE":
    case "REVERSED":
      return <InformationWarningIcon {...props} />
    default:
      return undefined
  }
}

function PaymentStatusTag({
  status,
  type,
  transactionType,
}: {
  status: PaymentStatus
  type: "CREDIT" | "DEBIT"
  transactionType?: PaymentTransactionTypes
}) {
  const tagTitle: string = useMemo(() => {
    return getStatusTitle({ status, type, transactionType })
  }, [status, type, transactionType])

  const bgColor: React.ComponentProps<typeof Box>["bgColor"] = useMemo(() => {
    switch (status) {
      case "SUCCESS":
      case "REFUNDED":
        return "surfaceSuccess"
      case "FAILURE":
      case "REVERSED":
        return "iconError"
      case "EXPIRED":
        return "iconMedium"
      default:
        return "surfaceWarning"
    }
  }, [status])

  return (
    <Box rounded="md" paddingY="1" paddingX="2" backgroundColor={bgColor}>
      <Text fontSize="c2" color="textOnSurface">
        {tagTitle}
      </Text>
    </Box>
  )
}

function PaymentStatusBanner({ status }: { status: PaymentStatus }) {
  const title: string = useMemo(() => {
    switch (status) {
      case "PENDING":
      case "DEEMED":
        return "We are still trying to complete this transaction. Please wait for a while."
      case "FAILURE":
        return "Sorry, we are unable to process this payment request"
      case "REVERSED":
        return "Amount is credited back to your wallet"
      default:
        return ""
    }
  }, [status])
  const helperIcon: React.ReactNode | undefined = useMemo(() => {
    switch (status) {
      case "PENDING":
      case "DEEMED":
        return <InformationWarningIcon />
      case "FAILURE":
        return <InformationCircleFilledIcon />
      case "REVERSED":
        return <CheckCircleSolidIcon />
      default:
        return undefined
    }
  }, [status])

  const colorStyles: {
    color: React.ComponentProps<typeof Box>["color"]
    bgColor: React.ComponentProps<typeof Box>["backgroundColor"]
  } = useMemo(() => {
    switch (status) {
      case "FAILURE":
        return { color: "textError", bgColor: "surfaceErrorLowest" }
      case "REVERSED":
        return { color: "textSuccess", bgColor: "surfaceSuccessLowest" }
      default:
        return { color: "textWarning", bgColor: "surfaceWarningLowest" }
    }
  }, [status])

  return (
    <Inline
      gap="3"
      backgroundColor={colorStyles.bgColor}
      rounded="md"
      padding="4"
      alignItems="center"
      color={colorStyles.color}
    >
      {helperIcon}
      <Text fontSize="c2">{title}</Text>
    </Inline>
  )
}

function TransactionReceiptWithDetails({
  to,
  from,
  type,
  status,
  amount,
  timestamp,
  otherDetails,
}: {
  amount: number
  type: "DEBIT" | "CREDIT"
  status: PaymentStatus
  timestamp?: Timestamp
  to?: PartiesInvolvedType
  from?: PartiesInvolvedType
  otherDetails: {
    [key: string]: string
  }
}) {
  return (
    <Stack backgroundColor="backgroundLight1">
      <Inline
        paddingY="2"
        paddingX="4"
        alignItems="center"
        justifyContent="between"
      >
        <CashbookWithNameIcon width="24" />
        <TransactionDate format="dd MMM yyyy, hh:mm a" timeStamp={timestamp} />
      </Inline>
      <Stack
        backgroundColor={
          status === "SUCCESS" || status === "REFUNDED"
            ? "surfaceSuccessLowest"
            : status === "FAILURE" || status === "REVERSED"
            ? "surfaceErrorLowest"
            : "surfaceNeutralLowest"
        }
        gap="2"
        marginX="4"
        padding="4"
        alignItems="center"
        justifyContent="center"
      >
        <Text
          fontSize="c1"
          color={
            status === "SUCCESS" || status === "REFUNDED"
              ? "textCashIn"
              : status === "FAILURE" || status === "REVERSED"
              ? "textError"
              : "textMedium"
          }
        >
          {status === "SUCCESS" && type === "DEBIT"
            ? "Paid Successfully"
            : status === "SUCCESS" && type === "CREDIT"
            ? "Received Successfully"
            : status === "REFUNDED" && type === "CREDIT"
            ? "Refund Received Successfully"
            : status === "FAILURE" || status === "REVERSED"
            ? "Transaction Failed"
            : ""}
        </Text>
        <Inline alignItems="center" gap="2">
          <Amount fontSize="h1" currency="inr" amount={amount || 0} />
          {status === "PENDING" ? (
            <ClockIcon color="iconWarning" />
          ) : (
            <SmallArrowHeadUpIcon
              rotate={type === "CREDIT" ? "180" : undefined}
              color={type === "DEBIT" ? "iconCashOut" : "iconCashIn"}
            />
          )}
        </Inline>
      </Stack>
      <Stack padding="4" gap="4">
        {to ? (
          <Inline gap="4" whiteSpace="preserve">
            <Avatar
              id={to.id}
              name={to.title || to.name}
              size="8"
              fontSize="b1"
            />
            <Stack gap="1">
              <Text fontSize="b3">To: {to.title || to.name}</Text>
              {to.subtext && (
                <Inline alignItems="center" gap="1" whiteSpace="preserve">
                  {to?.partyType === "ENTITY" ? null : (
                    <PaymentsIcons color="iconMedium" size="4" name={to.icon} />
                  )}
                  <Text fontSize="c3">{to.subtext}</Text>
                </Inline>
              )}
            </Stack>
          </Inline>
        ) : null}
        {from ? (
          <Inline gap="4">
            <Avatar
              id={from.id}
              name={from.title || from.name}
              size="8"
              fontSize="b1"
            />
            <Stack gap="1">
              <Text fontSize="b3">From: {from.title || from.name}</Text>
              {from.subtext && (
                <Inline gap="1" alignItems="center" whiteSpace="preserve">
                  <PaymentsIcons size="4" color="iconMedium" name={from.icon} />
                  <Text fontSize="c3">{from.subtext}</Text>
                </Inline>
              )}
            </Stack>
          </Inline>
        ) : null}
      </Stack>
      <Stack
        marginX="4"
        paddingX="3"
        paddingY="2"
        backgroundColor="surfaceNeutralLowest"
      >
        <Text color="textMedium" fontSize="c2">
          Other Details
        </Text>
      </Stack>
      <Stack as="ul" gap="4" paddingX="4" paddingY="5">
        {Object.keys(otherDetails).map((key) => {
          return otherDetails[key] ? (
            <Stack gap="2" key={key} paddingX="4">
              <Text fontSize="c2" color="textMedium">
                {key}
              </Text>
              <Text fontSize="b3">{otherDetails[key]}</Text>
            </Stack>
          ) : null
        })}
      </Stack>
      {to?.icon === "upi" || from?.icon === "upi" ? (
        <Inline
          marginX="4"
          paddingY="3"
          backgroundColor="surfaceNeutralLowest"
          alignItems="center"
          justifyContent="center"
        >
          <Box width="24">
            <img src={PoweredByUpi} alt="Powered By UPI" />
          </Box>
        </Inline>
      ) : null}
    </Stack>
  )
}

export function CopyTransactionsToCashbook({
  children,
  ...props
}: React.ComponentProps<typeof CopyToCashbook> & {
  children: (props: { open: () => void }) => React.ReactNode
}) {
  const state = useOverlayTriggerState({})
  return (
    <>
      {children({
        open: state.open,
      })}
      <Modal
        isOpen={state.isOpen}
        placement="right"
        onClose={state.close}
        title="Select Book"
      >
        <CopyToCashbook {...props} onClose={state.close} />
      </Modal>
    </>
  )
}

function CopyToCashbook({
  businessId,
  transactions,
  onClose,
}: {
  businessId: string
  transactions: PaymentsTransaction[]
  onClose?: () => void
}) {
  const { business } = useBusiness(businessId)
  const { books } = useBooksForBusinessId(businessId)

  const [q, setQuery] = useState<string>("")
  const [shouldSkipDuplicates, setShouldSkipDuplicates] =
    useState<boolean>(false)

  const [selectedBook, setSelectedBook] = useState<TBook | null>(null)

  const duplicatedTransactionsCount = useMemo(() => {
    if (selectedBook?.id) {
      return transactions.filter((t) => t.copied_to_books?.[selectedBook.id])
        .length
    }
    return 0
  }, [selectedBook?.id, transactions])

  const filteredBooks = useMemo(() => {
    return (
      books?.filter((book) =>
        book.name.toLowerCase().includes(q.toLowerCase())
      ) || []
    )
  }, [books, q])

  const npciTransactionIds = useMemo(() => {
    if (shouldSkipDuplicates && selectedBook?.id) {
      return transactions
        .filter((t) => !t.copied_to_books?.[selectedBook?.id])
        .map((t) => t.npci_txn_id)
    }
    return transactions.map((t) => t.npci_txn_id)
  }, [selectedBook?.id, shouldSkipDuplicates, transactions])

  return (
    <>
      <ModalBody>
        <Stack gap="6">
          <Stack gap="4">
            <Text fontSize="b3">
              Select a book to copy {transactions.length}{" "}
              {pluralize("transaction", transactions.length)} in {business.name}
            </Text>
            <Inline
              position="relative"
              rounded="md"
              height="10"
              paddingRight="2"
              alignItems="stretch"
              gap="6"
              borderWidth="1"
              backgroundColor="backgroundLight1"
              width="full"
              borderColor="borderOutline"
              className="bg-opacity-20 focus-within:border-blue-900 focus-within:ring-1 ring-blue-900"
            >
              <input
                type="search"
                name="q"
                autoComplete="off"
                placeholder="Search book name..."
                value={q}
                onChange={(e) => setQuery(e.currentTarget.value)}
                className="bg-transparent outline-none flex-1 pl-4 placeholder:gray-500"
              />
              <Inline
                as="button"
                type="button"
                alignItems="center"
                justifyContent="center"
                onClick={() => {
                  if (q) setQuery("")
                }}
              >
                {q.length ? (
                  <CancelIcon color="gray900" />
                ) : (
                  <SearchIcon color="gray500" />
                )}
              </Inline>
            </Inline>
          </Stack>
          <Stack as="ul">
            {filteredBooks?.length ? (
              filteredBooks.map((b, i) => {
                const isSelected = selectedBook?.id === b.id
                return (
                  <Inline
                    as="li"
                    key={b.id}
                    gap="4"
                    borderBottomWidth={
                      i !== filteredBooks.length - 1 ? "1" : "0"
                    }
                    onClick={() => {
                      if (selectedBook?.id !== b.id) {
                        setSelectedBook(b)
                      }
                    }}
                    cursor="pointer"
                    paddingY="4"
                  >
                    <Box paddingTop="1">
                      <Radio isSelected={isSelected} />
                    </Box>
                    <Stack gap="2">
                      <Text fontSize="s3">{b.name}</Text>
                      {b?.createdAt ? (
                        <Text fontSize="b3" color="textMedium">
                          Created On:{" "}
                          {formatDate(
                            timeStampToDate(b.createdAt),
                            "MMM do yyyy"
                          )}{" "}
                          | {b?.sharedWith?.length} Members
                        </Text>
                      ) : null}
                    </Stack>
                  </Inline>
                )
              })
            ) : (
              <Stack>
                {q.length ? (
                  <Stack gap="2">
                    <Text fontSize="b1">No results found!</Text>
                    <Text fontSize="b3" color="textMedium">
                      No results found for "{q}". Please search for a valid
                      book.
                    </Text>
                  </Stack>
                ) : (
                  <Stack gap="2">
                    <Text fontSize="b1">No books found!</Text>
                    <Text fontSize="b3" color="textMedium">
                      There are no books available in business. You can add new
                      books and then try again!.
                    </Text>
                  </Stack>
                )}
              </Stack>
            )}
          </Stack>
        </Stack>
      </ModalBody>
      <ModalFooter position="relative">
        {selectedBook?.id ? (
          <Inline
            position="absolute"
            className="-top-[76%]"
            left="0"
            width="full"
            backgroundColor="surfaceNeutralLowest"
            justifyContent="between"
            alignItems="center"
            paddingY="4"
            paddingX="6"
            as="button"
            gap="6"
          >
            <Stack textAlign="left" gap="2" width="full">
              <Text fontSize="c2" color="textMedium">
                Copy from
              </Text>
              <Text fontSize="b3">Payments</Text>
            </Stack>
            <Box>
              <ArrowLeftIcon className="rotate-180" />
            </Box>
            <Stack textAlign="left" width="full" gap="2">
              <Text fontSize="c2" color="textMedium">
                Paste to
              </Text>
              <Text fontSize="b3">{selectedBook.name}</Text>
            </Stack>
          </Inline>
        ) : null}
        <ConfirmCopyTransactionModal
          businessName={business.name}
          bookName={selectedBook?.name || ""}
          duplicatedTransactionsCount={duplicatedTransactionsCount}
          npciTransactionIds={npciTransactionIds}
          destBookId={selectedBook?.id || ""}
          businessId={businessId}
          setShouldSkipDuplicates={setShouldSkipDuplicates}
          onSuccess={onClose}
        >
          {({ open }) => (
            <CBButton
              level="primary"
              size="lg"
              onClick={open}
              disabled={Boolean(!selectedBook?.id)}
            >
              Copy {transactions.length}{" "}
              {pluralize("Transaction", transactions.length)}
            </CBButton>
          )}
        </ConfirmCopyTransactionModal>
      </ModalFooter>
    </>
  )
}

function ConfirmCopyTransactionModal({
  bookName,
  businessId,
  destBookId,
  businessName,
  npciTransactionIds,
  duplicatedTransactionsCount,
  children,
  onSuccess,
  setShouldSkipDuplicates,
}: {
  bookName: string
  businessId: string
  destBookId: string
  businessName: string
  npciTransactionIds: string[]
  duplicatedTransactionsCount?: number
  onSuccess?: () => void
  setShouldSkipDuplicates: (bool: boolean) => void
  children: (props: { open: () => void }) => React.ReactNode
}) {
  const navigate = useNavigate()
  const state = useOverlayTriggerState({})
  const copyToCashbook = useCopyToCashbook()

  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const [copied, setCopied] = useState<boolean>(false)
  const [skipDuplicates, setSkipDuplicates] = useState<boolean>(false)

  const duplicateTCopy = useMemo(() => {
    return `${pluralize("transaction", duplicatedTransactionsCount)}`
  }, [duplicatedTransactionsCount])

  const transactionCopy = useMemo(() => {
    return `${pluralize("transaction", npciTransactionIds.length)}`
  }, [npciTransactionIds])

  const pointers_for_duplicates = useMemo(() => {
    return [
      duplicatedTransactionsCount === npciTransactionIds.length
        ? `${
            duplicatedTransactionsCount > 1 ? "These" : "This"
          } ${duplicateTCopy} already exist in this book `
        : `${duplicatedTransactionsCount} of ${npciTransactionIds.length} ${duplicateTCopy} already exist in this book`,
      `If you confirm, duplicate entry will be created in '${bookName}'`,
    ]
  }, [
    bookName,
    duplicateTCopy,
    duplicatedTransactionsCount,
    npciTransactionIds,
  ])

  const pointers = useMemo(() => {
    return [
      `${npciTransactionIds.length} ${pluralize(
        "transaction",
        npciTransactionIds.length
      )} will be copied in ‘${bookName}’ of ${businessName}`,
      `This will change the net balance of ’${bookName}’`,
    ]
  }, [bookName, businessName, npciTransactionIds])

  const showingDuplicates = duplicatedTransactionsCount && !skipDuplicates

  function onClose() {
    if (copied) {
      onSuccess?.()
    }
    setSkipDuplicates(false)
    setShouldSkipDuplicates(false)
    setCopied(false)
    state.close()
  }

  async function onCopyTransactions() {
    trackEvent(TrackingEvents.PAYMENTS_COPY_STARTED, {
      numberOfSelectedEntries: npciTransactionIds.length,
    })
    setError(null)
    setLoading(true)
    try {
      await copyToCashbook({ businessId, destBookId, npciTransactionIds })
      setLoading(false)
      setCopied(true)
      trackEvent(TrackingEvents.PAYMENTS_COPY_COMPLETED, {
        numberOfSelectedEntries: npciTransactionIds.length,
      })
    } catch (e) {
      const err = e as Error
      setLoading(false)
      setError(err.message)
    }
  }

  return (
    <>
      {children({
        open: state.open,
      })}
      <Modal
        title={
          copied
            ? "Transaction Copied"
            : showingDuplicates
            ? `Duplicate ${duplicateTCopy} found`
            : `Copy ${transactionCopy}?`
        }
        isOpen={state.isOpen}
        onClose={onClose}
      >
        {copied ? (
          <ModalBody>
            <Stack gap="6" alignItems="center" justifyContent="center">
              <CheckCircleSolidIcon size="18" color="iconSuccess" />
              <Stack
                gap="3"
                textAlign="center"
                alignItems="center"
                justifyContent="center"
              >
                <Text fontSize="s1" textTransform="capitalize">
                  {npciTransactionIds.length} {transactionCopy} Copied
                  Successfully
                </Text>
                <Text
                  fontSize="b3"
                  color="textMedium"
                  textTransform="capitalize"
                >
                  You can open{" "}
                  <Text as="span" fontSize="s4">
                    ‘{bookName}’
                  </Text>{" "}
                  in {businessName}
                </Text>
              </Stack>
            </Stack>
          </ModalBody>
        ) : (
          <ModalBody>
            <Stack gap="4">
              <Text fontSize="b3">Are you sure?</Text>
              <Stack as="ul" gap="4">
                {(showingDuplicates ? pointers_for_duplicates : pointers).map(
                  (pointer) => (
                    <Inline key={pointer} gap="4" alignItems="center">
                      <Circle size="2" backgroundColor="iconLow" />
                      <Text>{pointer}</Text>
                    </Inline>
                  )
                )}
              </Stack>
            </Stack>
            {error ? <Alert status="error">{error}</Alert> : null}
          </ModalBody>
        )}
        {copied ? (
          <ModalFooter>
            <CBButton
              size="lg"
              level="primary"
              iconPlacement="right"
              onClick={() => {
                navigate(
                  `/businesses/${businessId}/cashbooks/${destBookId}/transactions`
                )
              }}
            >
              Open {bookName} <ArrowRightIcon />{" "}
            </CBButton>
          </ModalFooter>
        ) : showingDuplicates ? (
          <ModalFooter>
            {duplicatedTransactionsCount === npciTransactionIds.length ? (
              <CBButton
                size="lg"
                level="primary"
                disabled={loading}
                onClick={onClose}
              >
                Cancel
              </CBButton>
            ) : (
              <CBButton
                level="primary"
                size="lg"
                loading={loading}
                onClick={() => {
                  setSkipDuplicates(true)
                  setShouldSkipDuplicates(true)
                }}
              >
                Skip Duplicates
              </CBButton>
            )}
            <CBButton size="lg" status="danger" onClick={onCopyTransactions}>
              {loading ? "Duplicating..." : "Confirm All"}
            </CBButton>
          </ModalFooter>
        ) : (
          <ModalFooter>
            <CBButton
              level="primary"
              size="lg"
              loading={loading}
              onClick={onCopyTransactions}
            >
              {loading ? "Copying..." : "Yes"}
            </CBButton>
            <CBButton size="lg" disabled={loading} onClick={onClose}>
              No
            </CBButton>
          </ModalFooter>
        )}
      </Modal>
    </>
  )
}
