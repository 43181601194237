import {
  BUSINESS_PERMISSIONS,
  useBusiness,
  useBusinesses,
} from "@cashbook/data-store/businesses"
import {
  Box,
  Button,
  DataLoadingFallback,
  ExchangeIcon,
  Heading,
  Inline,
  LogoutIcon,
  PageMeta,
  Stack,
  Text,
  TrashIcon,
} from "@cashbook/web-components"
import { useMemo } from "react"
import { useParams } from "react-router-dom"
import { SuspenseWithPerf } from "reactfire"
import {
  ChangeOwnerInModal,
  DeleteBusinessInDialog,
  LeaveBusinessInDialog,
} from "../Business"
import ErrorBoundary from "../ErrorBoundary"
import { getWhatsAppSharingLink } from "@cashbook/util-general"
import config from "../config"

export default function BusinessLevelSettingsPage() {
  const { businessId } = useParams()
  if (!businessId) return null
  return (
    <ErrorBoundary>
      <SuspenseWithPerf
        fallback={<DataLoadingFallback label="Loading business details..." />}
        traceId="loading_book_details"
      >
        <BusinessLevelSettings key={businessId} businessId={businessId} />
      </SuspenseWithPerf>
    </ErrorBoundary>
  )
}

function BusinessLevelSettings({ businessId }: { businessId: string }) {
  const { businesses } = useBusinesses()
  const { business, authTeamMemberDetails, checkIfAuthenticatedTeamMemberCan } =
    useBusiness(businessId)
  const canChangeOwner = checkIfAuthenticatedTeamMemberCan(
    BUSINESS_PERMISSIONS.CHANGE_OWNER
  )

  const canLeaveBusiness = checkIfAuthenticatedTeamMemberCan(
    BUSINESS_PERMISSIONS.LEAVE_BUSINESS
  )
  const canDeleteBusiness = checkIfAuthenticatedTeamMemberCan(
    BUSINESS_PERMISSIONS.DELETE_BUSINESS
  )

  const walletIssued: boolean = useMemo(() => {
    return Boolean(authTeamMemberDetails.wallet_issued_by) || false
  }, [authTeamMemberDetails.wallet_issued_by])

  const enabledPayments: boolean = useMemo(() => {
    return business.payments?.enabled || false
  }, [business.payments?.enabled])

  const getRedirectUrl = () => {
    const index = businesses.findIndex((b) => b.id === businessId)
    if (businesses.length > 1) {
      if (index === 0) {
        return `/businesses/${businesses[1].id}/cashbooks`
      } else {
        return `/businesses/${businesses[index - 1].id}/cashbooks`
      }
    } else {
      return `/`
    }
  }

  return (
    <>
      <PageMeta>
        <title>Business Settings - {business.name}</title>
      </PageMeta>
      <Box
        key={businessId}
        bgColor="white"
        paddingY="6"
        paddingX={{ xs: "4", md: "8" }}
        minHeight={{ xs: "screen", sm: "0" }}
      >
        <Stack maxWidth="xl" gap="8">
          {canChangeOwner && (
            <Box borderWidth="1" rounded="md" padding="6">
              <Inline gap="2" alignItems="center" justifyContent="between">
                <Stack gap="2">
                  <Heading as="h3">Change Owner</Heading>
                  <Text fontSize="sm" color="gray500">
                    Current owner: You
                  </Text>
                </Stack>
                <ChangeOwnerInModal
                  businessId={businessId}
                  enabledPayments={enabledPayments}
                >
                  {({ onChangeClick }) => (
                    <Button
                      inline
                      level="tertiary"
                      status="warning"
                      onClick={onChangeClick}
                    >
                      <Box>
                        <ExchangeIcon color="orange900" />
                      </Box>
                      <Text color="orange900">Change Owner</Text>
                    </Button>
                  )}
                </ChangeOwnerInModal>
              </Inline>
            </Box>
          )}
          {canDeleteBusiness ? (
            <Box borderWidth="1" rounded="md" padding="6">
              <Inline gap="2" alignItems="center" justifyContent="between">
                <Stack gap="2">
                  <Heading as="h3">Delete Business</Heading>
                  <Text fontSize="sm" color="gray500">
                    This will delete your business permanently
                  </Text>
                </Stack>
                <DeleteBusinessInDialog
                  businessId={businessId}
                  enabledPayments={enabledPayments}
                  redirectUrl={getRedirectUrl()}
                >
                  {({ delete: deleteBusiness }) => (
                    <Button
                      inline
                      level="tertiary"
                      status="error"
                      onClick={() => {
                        deleteBusiness()
                      }}
                    >
                      <Box>
                        <TrashIcon />
                      </Box>
                      <Text>Delete business</Text>
                    </Button>
                  )}
                </DeleteBusinessInDialog>
              </Inline>
            </Box>
          ) : canLeaveBusiness ? (
            <Box borderWidth="1" rounded="md" padding="6">
              <Inline
                alignItems={{ xs: "start", sm: "center" }}
                gap="8"
                collapseBelow="sm"
                justifyContent="between"
              >
                <Stack gap="2">
                  <Heading as="h3">Leave Business</Heading>
                  <Text fontSize="sm" color="gray500">
                    You will lose access to this business
                  </Text>
                </Stack>
                <LeaveBusinessInDialog
                  businessId={businessId}
                  businessName={business.name}
                  redirectUrl={getRedirectUrl()}
                  walletIssued={walletIssued}
                  memberRole={authTeamMemberDetails.role.id}
                >
                  {({ onLeaveClick }) => (
                    <Button
                      inline
                      level="tertiary"
                      status="error"
                      onClick={() => {
                        if (authTeamMemberDetails?.wallet?.min_kyc) {
                          const message = `Hi, I would like to leave ${business?.name} (Payments Wallet Active) (Web)`
                          const url = getWhatsAppSharingLink({
                            text: message,
                            phoneNumber: config.supportPhoneNumber,
                          })
                          window.open(url, "_blank") // Open WhatsApp in a new tab
                        } else {
                          onLeaveClick()
                        }
                      }}
                    >
                      <Box>
                        <LogoutIcon />
                      </Box>
                      <Text>Leave business</Text>
                    </Button>
                  )}
                </LeaveBusinessInDialog>
              </Inline>
            </Box>
          ) : null}
        </Stack>
      </Box>
    </>
  )
}
