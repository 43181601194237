import {
  ArrowRightIcon,
  Box,
  Circle,
  DataLoadingFallback,
  HideEntriesIcon,
  Inline,
  PageMeta,
  Stack,
  Text,
  UnHideIcon,
  Button,
  AddMoneyIcon,
  SkeletonTitle,
  TransactionDate,
  Time,
  ExcelFileIcon,
  SkeletonRows,
  SkeletonAvatar,
  CardPendingIcon,
  CardDeactivatedIcon,
} from "@cashbook/web-components"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import { SuspenseWithPerf } from "reactfire"
import ErrorBoundary from "../ErrorBoundary"
import { TBusinessUser, useBusiness } from "@cashbook/data-store/businesses"
import { Amount, currencies_supported } from "../support/Intl"
import { SyntheticEvent, useMemo, useState, useSyncExternalStore } from "react"
import {
  PaymentsTransaction,
  Wallet,
  transformDate,
  useDashboard,
  useStaffWallets,
} from "@cashbook/data-store/payments"
import {
  Avatar,
  GeneralErrorHandler,
  PaymentsIcons,
  TransactionDetailsInModal,
  getTransactionModeOfPayment,
} from "../Payments"
import { TUser, useProfile } from "@cashbook/data-store/users"
import {
  startOfDay,
  endOfDay,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
} from "@cashbook/util-dates"
import config from "../config"
import { ViewMasterWalletDetailsInModal } from "../Payments/MasterWallet"
import { pluralize } from "@cashbook/util-general"
import { TrackingEvents, trackEvent } from "@cashbook/util-tracking"
import {
  showPaymentsStore,
  userWalletStore,
} from "@cashbook/data-store/storage"
import ObopayLogo from "../Payments/ObopayLogo.png"
import { NotInWeb } from "../support"

export default function PaymentsDashboardPage() {
  const { businessId } = useParams()
  if (!businessId) return null
  return (
    <ErrorBoundary>
      <SuspenseWithPerf
        fallback={<DataLoadingFallback label="Loading payments details..." />}
        traceId="loading_payments_details"
      >
        <PaymentsDashboard key={businessId} businessId={businessId} />
      </SuspenseWithPerf>
    </ErrorBoundary>
  )
}

const todaysDate = new Date()

export type OverviewFilterOptionsIds = "today" | "this_week" | "this_month"
export type OverviewFilterOption = {
  id: OverviewFilterOptionsIds
  title: string
  dates: [Date, Date]
}
const overviewFilterOptions: OverviewFilterOption[] = [
  {
    id: "this_month",
    title: "This Month",
    dates: [startOfMonth(todaysDate), endOfMonth(todaysDate)],
  },
  {
    id: "this_week",
    title: "This Week",
    dates: [startOfWeek(todaysDate), endOfWeek(todaysDate)],
  },
  {
    id: "today",
    title: "Today",
    dates: [startOfDay(todaysDate), endOfDay(todaysDate)],
  },
]

function PaymentsDashboard({ businessId }: { businessId: string }) {
  const [activeOverviewFilter, setOverviewFilter] =
    useState<OverviewFilterOption>(overviewFilterOptions[0])

  const navigate = useNavigate()
  const { user } = useProfile()
  const { pathname } = useLocation()
  const { business, getTeamMemberInfoForId } = useBusiness(businessId)
  const {
    error,
    status,
    masterWalletDetails,
    totalExpenses,
    totalCollections,
    transactionsCount,
    businessTransactions,
    refreshPage,
    handleDurationChange,
  } = useDashboard(businessId, {
    from_datetime: activeOverviewFilter.dates?.[0],
    to_datetime: activeOverviewFilter.dates?.[1],
  })

  const { teamWallets, currentUserWallet } = useStaffWallets(businessId)

  return (
    <>
      <PageMeta>
        <title> Dashboard (Payments) - {business.name}</title>
      </PageMeta>
      <Box
        key={businessId}
        backgroundColor="backgroundLight2"
        paddingX="8"
        height="full"
      >
        {status === "in_progress" || status === "init" ? (
          <Inline height="full">
            <Stack
              width="full"
              paddingY="8"
              gap="8"
              maxWidth="3xl"
              minWidth="screenMd"
              borderRightWidth="1"
              paddingRight="8"
            >
              <Inline
                borderWidth="1"
                borderColor="borderOutline"
                rounded="md"
                paddingY="4"
                paddingX="6"
                justifyContent="between"
                backgroundColor="backgroundLight1"
              >
                <Stack gap="2" width="full" maxWidth="xs">
                  <SkeletonTitle height="3" />
                  <SkeletonTitle width="full" />
                </Stack>
              </Inline>
              <Inline
                borderWidth="1"
                borderColor="borderOutline"
                rounded="md"
                paddingY="4"
                paddingX="6"
                justifyContent="between"
                backgroundColor="backgroundLight1"
              >
                <Stack gap="2" width="full" maxWidth="xs">
                  <SkeletonTitle height="3" />
                  <SkeletonTitle width="full" />
                  <SkeletonTitle width="full" />
                  <SkeletonTitle width="full" />
                </Stack>
              </Inline>
              <Box
                as="table"
                backgroundColor="backgroundLight1"
                rounded="md"
                borderWidth="1"
              >
                <SkeletonRows numOfRows={6} numOfCols={4} />
              </Box>
            </Stack>
            <Stack gap="8" width="full" padding="8">
              <Inline
                borderWidth="1"
                borderColor="borderOutline"
                rounded="md"
                paddingY="4"
                paddingX="6"
                gap="3"
                alignItems="center"
                backgroundColor="backgroundLight1"
              >
                <SkeletonAvatar />
                <Stack gap="2" width="full" maxWidth="xs">
                  <SkeletonTitle height="3" />
                  <SkeletonTitle width="full" />
                </Stack>
              </Inline>
              <Inline
                borderWidth="1"
                borderColor="borderOutline"
                rounded="md"
                paddingY="4"
                paddingX="6"
                gap="3"
                alignItems="center"
                backgroundColor="backgroundLight1"
              >
                <SkeletonAvatar />
                <Stack gap="2" width="full" maxWidth="xs">
                  <SkeletonTitle height="3" />
                  <SkeletonTitle width="full" />
                </Stack>
              </Inline>
              <Inline
                borderWidth="1"
                borderColor="borderOutline"
                rounded="md"
                paddingY="4"
                paddingX="6"
                gap="3"
                alignItems="center"
                backgroundColor="backgroundLight1"
              >
                <SkeletonAvatar />
                <Stack gap="2" width="full" maxWidth="xs">
                  <SkeletonTitle height="3" />
                  <SkeletonTitle width="full" />
                </Stack>
              </Inline>
            </Stack>
          </Inline>
        ) : error ? (
          <Stack height="full">
            <GeneralErrorHandler onRetry={refreshPage} />
          </Stack>
        ) : (
          <Inline>
            <Stack
              width="full"
              maxWidth={{ xl: "3xl", lg: "4xl", xs: "full" }}
              paddingRight={{ lg: "8", xs: "0" }}
              paddingY="6"
              gap="10"
              className="lg:border-r-[1px] xl:min-w-[760px]"
              borderColor="borderOutline"
            >
              {Boolean(masterWalletDetails) && (
                <Inline
                  backgroundColor="surfaceDefault"
                  borderColor="borderOutline"
                  paddingY="4"
                  borderWidth="1"
                  rounded="md"
                  paddingX="6"
                  alignItems="center"
                  justifyContent="between"
                  as={Link}
                  gap="2"
                  onClick={() => {
                    trackEvent(TrackingEvents.PAYMENTS_TAB_SWITCHED, {
                      currentTab: "masterWallet",
                      previousTab: "dashboard",
                    })
                  }}
                  to={pathname.replace("dashboard", "master-wallet")}
                  className="hover:bg-blue-5"
                >
                  <Stack gap="6">
                    <Inline gap="2" alignItems="center">
                      <Text fontSize="s3">Master Wallet</Text>
                      <Text fontSize="b4" color="textMedium">
                        (
                        {hideAccountString(
                          masterWalletDetails?.account_no || ""
                        )}
                        )
                      </Text>
                      <ArrowRightIcon color="textMedium" />
                    </Inline>
                    <Stack gap="2">
                      <Text fontSize="c3">Available Balance</Text>
                      <HideAbleBalance
                        amount={masterWalletDetails?.balance || "0"}
                      />
                    </Stack>
                  </Stack>
                  <ViewMasterWalletDetailsInModal
                    from="addMoney"
                    businessName={business.name}
                    ifscCode={masterWalletDetails?.ifsc_code}
                    accountNumber={masterWalletDetails?.account_no}
                    corporateName={masterWalletDetails?.corporateName}
                  >
                    {({ open }) => (
                      <Button
                        size="lg"
                        status="success"
                        onClick={(e) => {
                          e.preventDefault()
                          open()
                        }}
                      >
                        <Box>
                          <AddMoneyIcon />
                        </Box>
                        <Text className="xl:block hidden">Add Money</Text>
                      </Button>
                    )}
                  </ViewMasterWalletDetailsInModal>
                </Inline>
              )}
              <Stack gap="4">
                <Inline
                  flexDirection={{ xl: "row", xs: "col" }}
                  alignItems={{ xl: "center" }}
                  gap={{ xl: "2", lg: "4" }}
                  justifyContent="between"
                >
                  <Text fontSize="s1">Overall Business Expenses</Text>
                  <Inline as="ul" gap="4">
                    {overviewFilterOptions.map((option) => {
                      const isSelected = option.id === activeOverviewFilter.id
                      return (
                        <Box
                          key={option.id}
                          borderWidth="1"
                          rounded="md"
                          borderColor={
                            isSelected ? "borderPrimaryLow" : "borderOutline"
                          }
                          paddingY="2"
                          paddingX="3"
                          backgroundColor={
                            isSelected
                              ? "surfacePrimaryLowest"
                              : "surfaceDefault"
                          }
                          cursor="pointer"
                          onClick={() => {
                            if (isSelected) return
                            trackEvent(
                              TrackingEvents.PAYMENT_TRANSACTIONS_FILTER_APPLIED,
                              {
                                from: "businessTransactionsSummaryCard",
                                dateFilter: option.id,
                              }
                            )
                            setOverviewFilter(option)
                            handleDurationChange(
                              option.dates[0],
                              option.dates[1]
                            )
                          }}
                          className="hover:bg-blue-5"
                        >
                          <Text
                            fontSize={isSelected ? "c1" : "c2"}
                            color={isSelected ? "textPrimary" : "textHigh"}
                          >
                            {option.title}
                          </Text>
                        </Box>
                      )
                    })}
                  </Inline>
                </Inline>
                <Stack gap="8">
                  <Stack
                    backgroundColor="surfaceDefault"
                    rounded="md"
                    borderWidth="1"
                    borderColor="borderOutline"
                  >
                    <Inline
                      alignItems={{ xl: "center" }}
                      justifyContent="between"
                      padding="6"
                      gap={{ xl: "0", lg: "6" }}
                      flexDirection={{ xl: "row", lg: "col" }}
                    >
                      <Inline alignItems="center">
                        <Stack gap="2">
                          <Inline fontSize="c2" gap="2">
                            <Text>Total Expenses</Text>
                          </Inline>
                          <Amount
                            fontSize="s1"
                            color="textCashOut"
                            currency="inr"
                            amount={
                              totalExpenses && totalExpenses > 0
                                ? totalExpenses
                                : 0
                            }
                          />
                        </Stack>
                        <Box
                          borderWidth="1"
                          borderColor="borderDividers"
                          marginX="12"
                          height="12"
                        />
                        <Stack gap="2">
                          <Inline fontSize="c2" gap="2">
                            <Text>Total Collections</Text>
                          </Inline>
                          <Amount
                            fontSize="s1"
                            color="textCashIn"
                            currency="inr"
                            amount={
                              totalCollections && totalCollections > 0
                                ? totalCollections
                                : 0
                            }
                          />
                        </Stack>
                      </Inline>

                      <Button
                        onClick={() =>
                          navigate(
                            pathname.replace("dashboard", "all-transactions")
                          )
                        }
                        level="primary"
                        size="lg"
                      >
                        <Box>
                          <ExcelFileIcon />
                        </Box>
                        <Text>View All Transactions</Text>
                      </Button>
                    </Inline>
                  </Stack>
                  <Stack gap="4">
                    <Inline alignItems="center" justifyContent="between">
                      <Text fontSize="s4">
                        Recent {pluralize("Transaction", transactionsCount)} (
                        {businessTransactions?.length || 0})
                      </Text>
                      <Button
                        inline
                        onClick={() =>
                          navigate(
                            pathname.replace("dashboard", "all-transactions")
                          )
                        }
                      >
                        <Text>View All</Text>
                        <Box>
                          <ArrowRightIcon />
                        </Box>
                      </Button>
                    </Inline>
                    {businessTransactions?.length ? (
                      <Stack
                        as="ul"
                        rounded="md"
                        borderWidth="1"
                        borderColor="borderOutline"
                        backgroundColor="surfaceDefault"
                      >
                        {businessTransactions.map((transaction) => (
                          <Box
                            key={transaction.id}
                            borderBottomWidth={"1"}
                            as="li"
                            rounded="md"
                            cursor="pointer"
                            className="last:border-b-[0px] hover:bg-blue-5"
                            borderColor="borderOutline"
                          >
                            <TransactionItem
                              user={user}
                              getBusinessUser={getTeamMemberInfoForId}
                              transaction={transaction}
                            />
                          </Box>
                        ))}
                      </Stack>
                    ) : (
                      <Stack
                        justifyContent="center"
                        alignItems="center"
                        gap="2"
                        paddingY="24"
                      >
                        <Text fontSize="s1">No transactions found</Text>
                        <Text fontSize="b3" color="textMedium">
                          Make payments from your wallet on {config.appTitle}{" "}
                          App to see all transactions
                        </Text>
                      </Stack>
                    )}
                  </Stack>
                  <Box style={{ width: 140, height: 32 }} alignSelf="center">
                    <img src={ObopayLogo} alt={`Obopay Logo`} />
                  </Box>
                </Stack>
                <Box className="lg:hidden block">
                  <StaffWallets
                    pathName={pathname}
                    teamWallets={teamWallets}
                    currentUserWallet={currentUserWallet}
                    onViewAllClick={() => {
                      navigate(pathname.replace("dashboard", "staff-wallets"))
                    }}
                    businessId={businessId}
                  />
                </Box>
              </Stack>
            </Stack>
            <Box
              width="full"
              maxWidth={{ xl: "sm", lg: "xs" }}
              className="lg:block hidden"
            >
              <StaffWallets
                pathName={pathname}
                teamWallets={teamWallets}
                currentUserWallet={currentUserWallet}
                onViewAllClick={() => {
                  navigate(pathname.replace("dashboard", "staff-wallets"))
                }}
                businessId={businessId}
              />
            </Box>
          </Inline>
        )}
      </Box>
    </>
  )
}

function StaffWallets({
  pathName,
  teamWallets,
  currentUserWallet,
  businessId,
  onViewAllClick,
}: {
  pathName: string
  currentUserWallet: Wallet
  teamWallets?: Wallet[]
  businessId: string
  onViewAllClick: () => void
}) {
  const showPayments = useSyncExternalStore(
    showPaymentsStore.subscribe,
    showPaymentsStore.getShowPaymentsList
  )
  return (
    <Stack
      gap="6"
      width="full"
      maxWidth={{ xl: "md", lg: "md" }}
      paddingY="6"
      paddingLeft={{ lg: "8", xs: "0" }}
    >
      <Stack gap="4">
        <Inline alignItems="center" justifyContent="between">
          <Text fontSize="s4">Member Wallets</Text>
          <Button inline onClick={onViewAllClick}>
            View All
            <ArrowRightIcon />
          </Button>
        </Inline>
        {currentUserWallet.kycStatus === "full_kyc" ? (
          <WalletItem
            wallet={currentUserWallet}
            onClick={() => {
              userWalletStore.setUserWallet({
                uid: currentUserWallet.uid,
                name: "Your Wallet",
                phoneNumber: currentUserWallet.phoneNumber || "",
                kycStatus: currentUserWallet.kycStatus,
                balance: currentUserWallet.balance,
              })
            }}
            to={pathName.replace(
              "dashboard",
              `staff-wallets/${currentUserWallet.uid}?header=none`
            )}
          />
        ) : showPayments[businessId]?.can_enable_wallet ? (
          <NotInWeb heading="Use mobile app to activate your wallet">
            {({ onDisplay }) => <InActiveWalletCard onClick={onDisplay} />}
          </NotInWeb>
        ) : null}
      </Stack>
      {teamWallets?.length ? (
        <>
          <Box borderBottomWidth="1" borderColor="borderOutline" />
          <Stack as="ul" gap="4">
            {teamWallets.map((memberWallet) => (
              <WalletItem
                key={memberWallet.uid}
                wallet={memberWallet}
                onClick={() => {
                  userWalletStore.setUserWallet(memberWallet)
                }}
                to={pathName.replace(
                  "dashboard",
                  `staff-wallets/${
                    memberWallet.deactivation
                      ? memberWallet.walletId
                      : memberWallet.uid
                  }?header=none`
                )}
              />
            ))}
          </Stack>
        </>
      ) : null}
    </Stack>
  )
}

function WalletItem({
  to,
  wallet,
  onClick,
}: {
  to: string
  wallet: Wallet
  onClick?: () => void
}) {
  return (
    <Inline
      backgroundColor="surfaceDefault"
      borderWidth="1"
      rounded="md"
      padding="4"
      justifyContent="between"
      alignItems="start"
      gap="4"
      as={Link}
      to={to}
      onClick={onClick}
      borderColor="borderOutline"
      className="hover:bg-blue-5"
    >
      <Inline gap="2">
        {wallet.kycStatus !== "full_kyc" || wallet.deactivation ? (
          <Circle
            size="10"
            backgroundColor={
              wallet?.deactivation
                ? "surfaceNeutralLowest"
                : "surfaceWarningLowest"
            }
          >
            {wallet.deactivation ? (
              <CardDeactivatedIcon color="iconMedium" />
            ) : (
              <CardPendingIcon color="iconWarning" />
            )}
          </Circle>
        ) : (
          <Avatar size="6" fontSize="c2" id={wallet.uid} name={wallet.name} />
        )}
        <Stack gap="2" alignItems="start">
          <Text fontSize="s4" className="max-w-full line-clamp-1">
            {wallet.name}
          </Text>
          <Text color="textMedium" fontSize="c2">
            {wallet.phoneNumber}
          </Text>
          {wallet.kycStatus !== "full_kyc" || wallet?.deactivation ? (
            <Box
              backgroundColor={
                wallet.deactivation
                  ? "surfaceNeutralLowest"
                  : "surfaceWarningLowest"
              }
              paddingX="2"
              paddingY="1"
              rounded="md"
              marginTop="2"
              className="lg:block hidden"
            >
              <Text
                fontSize="c1"
                color={wallet.deactivation ? "textMedium" : "textWarning"}
              >
                {wallet.deactivation
                  ? "Deactivated!"
                  : wallet.kycStatus === "init"
                  ? "KYC Pending"
                  : "Full KYC Pending"}
              </Text>
            </Box>
          ) : null}
        </Stack>
      </Inline>
      {wallet.deactivation ? (
        <Box
          backgroundColor={"surfaceNeutralLowest"}
          paddingX="2"
          paddingY="1"
          rounded="md"
          className="lg:hidden block"
        >
          <Text fontSize="c1" color="textMedium">
            Deactivated!
          </Text>
        </Box>
      ) : wallet.kycStatus === "init" ? (
        <Box
          backgroundColor={"surfaceWarningLowest"}
          paddingX="2"
          paddingY="1"
          rounded="md"
          className="lg:hidden block"
        >
          <Text fontSize="c1" color="textWarning">
            KYC Pending
          </Text>
        </Box>
      ) : wallet.kycStatus === "min_kyc" ? (
        <Box
          backgroundColor={"surfaceWarningLowest"}
          paddingX="2"
          paddingY="1"
          rounded="md"
          className="lg:hidden block"
        >
          <Text fontSize="c1" color="textWarning">
            Full KYC Pending
          </Text>
        </Box>
      ) : (
        <Stack gap="2" alignItems="end">
          <Amount
            amount={wallet.balance || 0}
            fontSize="s4"
            color={wallet?.balance === 0 ? "textCashOut" : undefined}
          />
          <Text color="textMedium">Balance</Text>
        </Stack>
      )}
    </Inline>
  )
}

function HideAbleBalance({ amount }: { amount: string }) {
  const [visible, setVisible] = useState<boolean>(false)
  return (
    <Inline fontSize="s1" gap="2">
      <Text>{currencies_supported["inr"]}</Text>
      {visible ? (
        <Amount fontSize="s1" amount={Number(amount) || 0} />
      ) : (
        <Inline gap="2" alignItems="center" justifyContent="center">
          <Circle size="2" backgroundColor="iconHigh" />
          <Circle size="2" backgroundColor="iconHigh" />
          <Circle size="2" backgroundColor="iconHigh" />
        </Inline>
      )}
      <Box
        cursor="pointer"
        onClick={(e: SyntheticEvent) => {
          e.preventDefault()
          e.stopPropagation()
          setVisible((prev) => !prev)
        }}
      >
        {visible ? (
          <HideEntriesIcon size="5" color="iconMedium" />
        ) : (
          <UnHideIcon size="5" color="iconMedium" />
        )}
      </Box>
    </Inline>
  )
}

function hideAccountString(string: string, length?: number) {
  return `${string.slice(0, length || 6).replace(/./g, "*")}${string.slice(
    length || 6,
    string.length
  )}`
}

function TransactionItem({
  user,
  transaction,
  getBusinessUser,
}: {
  user: TUser
  transaction: PaymentsTransaction
  getBusinessUser?: (id: string) => TBusinessUser | null
}) {
  const {
    party,
    id,
    timestamp,
    attachments,
    created_at,
    type,
    amount,
    business_id,
    transaction_type,
    npci_txn_id,
  } = transaction

  const [numOfAttachments, setNumOfAttachmentIds] = useState<number>(
    attachments?.length || 0
  )

  const transactionBy: string = useMemo(() => {
    const bUser = getBusinessUser?.(transaction.created_by || "")
    if (user.uid === bUser?.id) {
      return type === "DEBIT" ? "by You" : "to You"
    }
    return type === "DEBIT" && bUser?.name
      ? `by ${bUser?.name}`
      : bUser?.name
      ? `to ${bUser?.name}`
      : ""
  }, [getBusinessUser, transaction.created_by, type, user.uid])

  const transactionType = useMemo(() => {
    return getTransactionModeOfPayment(transaction_type)
  }, [transaction_type])

  function onAttachmentAdded() {
    setNumOfAttachmentIds((prevCount) => {
      return prevCount + 1
    })
  }

  function onAttachmentDeleted() {
    setNumOfAttachmentIds((prevCount) => {
      if (prevCount > 0) return prevCount - 1
      return 0
    })
  }

  return (
    <TransactionDetailsInModal
      businessId={business_id || ""}
      transactionId={id}
      npciTransactionId={npci_txn_id}
      isBillAttached={numOfAttachments > 0}
      onAttachmentAdded={onAttachmentAdded}
      onAttachmentDeleted={onAttachmentDeleted}
    >
      {({ open }) => (
        <Inline padding="4" justifyContent="between" onClick={open}>
          <Inline gap="4">
            <Avatar
              size="8"
              fontSize="b1"
              id={party?.id || id}
              name={party?.name || ""}
              type={party?.entity_type === "ENTITY" ? "merchant" : "individual"}
            />
            <Stack gap="4">
              <Text fontSize="b3">{party?.name || "CB User"}</Text>
              <Stack gap="1" fontSize="c2" color="textMedium">
                <Inline gap="1">
                  <TransactionDate
                    timeStamp={transformDate(timestamp || created_at)}
                  />
                  <Text>•</Text>
                  <Time
                    timeStamp={transformDate(timestamp || created_at)}
                    format="hh:mm a"
                  />
                </Inline>
                <Text>{transactionBy}</Text>
              </Stack>
            </Stack>
          </Inline>
          <Box>
            <Inline alignItems="center" gap="2">
              <Amount
                fontSize="s4"
                type={type === "DEBIT" ? "cash-out" : "cash-in"}
                currency="inr"
                amount={Number(amount) || 0}
              />
              <PaymentsIcons
                color="iconMedium"
                name={transactionType}
                size="3"
              />
            </Inline>
          </Box>
        </Inline>
      )}
    </TransactionDetailsInModal>
  )
}

export function InActiveWalletCard({ onClick }: { onClick: () => void }) {
  return (
    <Stack
      backgroundColor="surfaceDefault"
      borderWidth="1"
      rounded="md"
      padding="4"
      alignItems="start"
      gap="4"
      onClick={onClick}
      borderColor="borderOutline"
      className="hover:bg-blue-5"
    >
      <Inline alignItems="center" gap="4">
        <Avatar
          size="6"
          fontSize="c2"
          id={"inActiveWallet"}
          name={"Your Wallet"}
        />
        <Text fontSize="s4" className="max-w-full line-clamp-1">
          {"Your Wallet"}
        </Text>
      </Inline>
      <Button
        level="secondary"
        onClick={() => {
          onClick()
        }}
        fullWidth
      >
        {"Activate Wallet"}
      </Button>
    </Stack>
  )
}
