import {
  DataLoadingFallback,
  PageMeta,
  Text,
  Heading,
  Box,
  Stack,
  Inline,
  Button,
  ArrowRightIcon,
  UserAddIcon,
  BookIcon,
} from "@cashbook/web-components"
import React, { useEffect, useMemo, useState } from "react"
import {
  BUSINESS_PERMISSIONS,
  getBusinessRoleDetails,
  TBusinessInvitation,
  TBusinessMember,
  useBusiness,
  useShareBusinessInvitations,
} from "@cashbook/data-store/businesses"
import { useNavigate, useParams } from "react-router-dom"
import { SuspenseWithPerf } from "reactfire"
import ErrorBoundary from "../ErrorBoundary"
import { MemberAvatar } from "../Books"
import { useProfile } from "@cashbook/data-store/users"
import {
  AddedOrInvitedMemberDialog,
  AddTeamMemberInBusinessInModal,
  BusinessRoleDetails,
  ResendInvitationInModal,
  ShowAllBusinessRolesAndPermissionsInModal,
} from "../Business"
import { parsePhoneNumber } from "react-phone-number-input"
import { useBooksForBusinessId } from "@cashbook/data-store/books"
import { pluralize } from "@cashbook/util-general"
import AddTeamMemberGuide from "../Business/AddTeamFirstTime.png"
import { trackEvent, TrackingEvents } from "@cashbook/util-tracking"

export default function BusinessTeamSettingsPage() {
  const { businessId } = useParams()
  if (!businessId) return null
  return (
    <ErrorBoundary>
      <SuspenseWithPerf
        fallback={<DataLoadingFallback label="Loading business details..." />}
        traceId="loading_business_details"
      >
        <BusinessTeamSettings key={businessId} businessId={businessId} />
      </SuspenseWithPerf>
    </ErrorBoundary>
  )
}

function BusinessTeamSettings({ businessId }: { businessId: string }) {
  const { user } = useProfile()
  const navigate = useNavigate()
  const {
    business,
    businessTeam,
    businessRoleWiseTeam,
    authTeamMemberDetails,
    checkIfAuthenticatedTeamMemberCan,
  } = useBusiness(businessId)
  const { invitations } = useShareBusinessInvitations(businessId)
  const [showMemberAddedDialog, setShowMemberAddedDialog] = useState<{
    name?: string
    email?: string
    phoneNumber?: string
    isInvited?: boolean
    role?: string
  }>({})
  const { getBooksForTeamMember } = useBooksForBusinessId(businessId)
  // The book has been deleted (but sub-collections are not!!)
  // https://firebase.google.com/docs/firestore/manage-data/delete-data#delete_documents
  const ownerOrPartners = useMemo(() => {
    const members: TBusinessMember[] = businessRoleWiseTeam["owner"].concat(
      businessRoleWiseTeam["partner"]
    )
    if (members.length) {
      members.sort((a, b) => {
        if (a.uid === authTeamMemberDetails.uid) return -1
        if (b.uid === authTeamMemberDetails.uid) return 1
        // now prefer owners, partners, then staff.
        const roles = ["partner", "owner"]
        const aRoleIndex = roles.indexOf(a.role.id)
        const bRoleIndex = roles.indexOf(b.role.id)
        if (aRoleIndex !== bRoleIndex) {
          // prefer the owner role first
          return aRoleIndex > bRoleIndex ? -1 : 1
        }
        return -1
      })
    }
    return members
  }, [authTeamMemberDetails, businessRoleWiseTeam])
  const businessStaff = businessRoleWiseTeam["staff"]

  const canAddTeamMembers = checkIfAuthenticatedTeamMemberCan(
    BUSINESS_PERMISSIONS.ADD_TEAM_MEMBER
  )

  const authMemberIsOwnerOrPartner =
    authTeamMemberDetails.role.id === "owner" ||
    authTeamMemberDetails.role.id === "partner"

  const totalMembers = authMemberIsOwnerOrPartner
    ? business.sharedWith.length + invitations.length
    : business.sharedWith.length

  useEffect(() => {
    trackEvent(TrackingEvents.BUSINESS_TEAM_VIEWED, {
      staffSize: businessRoleWiseTeam.staff.length,
      numOfPartners: businessRoleWiseTeam.partner.length,
      role: authTeamMemberDetails.role.id,
      from: "profile",
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [showAddMembersModal, setShowAddMembersModal] = useState<boolean>(false)

  const addToBooksClick = ({
    email,
    phoneNumber,
  }: {
    email?: string
    phoneNumber?: string
  }) => {
    setShowMemberAddedDialog({})
    const newAddedMember = businessTeam.find(
      (member) => member.phoneNumber === phoneNumber || member.email === email
    )
    if (newAddedMember?.uid) {
      navigate(`${newAddedMember?.uid}?action=addToBooks`)
    }
  }

  if (!business.name) return null
  return (
    <>
      <PageMeta>
        <title>Team - {business.name}</title>
      </PageMeta>
      <Box
        key={businessId}
        bgColor="white"
        paddingY="6"
        paddingX={{ xs: "4", md: "8" }}
        minHeight={{ xs: "screen", sm: "0" }}
      >
        <Stack maxWidth="xl" gap="6">
          <Stack gap="6">
            {canAddTeamMembers && (
              <Box borderWidth="1" rounded="md" padding="6">
                <Inline
                  alignItems={{ xs: "start", sm: "center" }}
                  gap="8"
                  collapseBelow="md"
                >
                  <Stack gap="2" minWidth="min">
                    <Heading as="h3" fontSize="md" fontWeight="semibold">
                      Business Team
                    </Heading>
                    <Text color="gray500" fontSize="sm">
                      Add your business partners or staffs to this business and
                      manage cashflow together
                    </Text>
                  </Stack>
                  <AddTeamMemberInBusinessInModal
                    businessId={businessId}
                    defaultState={showAddMembersModal}
                    onSuccess={(values) => {
                      setShowAddMembersModal(false)
                      setTimeout(() => {
                        setShowMemberAddedDialog(values)
                      }, 500)
                    }}
                  >
                    {({ onAddClick }) => (
                      <Box className="w-full lg:w-[80%]">
                        <Button
                          fullWidth
                          level="primary"
                          size="base"
                          onClick={onAddClick}
                        >
                          <Box>
                            <UserAddIcon />
                          </Box>
                          Add team member
                        </Button>
                      </Box>
                    )}
                  </AddTeamMemberInBusinessInModal>
                </Inline>
              </Box>
            )}
            <Inline alignItems="center" justifyContent="between">
              <Heading as="h3" fontSize="md" fontWeight="semibold">
                Total Members ({totalMembers})
              </Heading>
              <ShowAllBusinessRolesAndPermissionsInModal
                userRole={authTeamMemberDetails.role.id}
              >
                {({ onDisplay }) => (
                  <Button inline level="tertiary" onClick={onDisplay}>
                    View roles & permissions
                    <Box>
                      <ArrowRightIcon />
                    </Box>
                  </Button>
                )}
              </ShowAllBusinessRolesAndPermissionsInModal>
            </Inline>
            {authMemberIsOwnerOrPartner && invitations.length ? (
              <Box>
                <Text fontWeight="semibold" color="gray500">
                  Pending Invitations
                </Text>
                <Stack as="ol">
                  {invitations.map((invitation, index) => (
                    <Box
                      as="li"
                      key={invitation.id}
                      paddingY="4"
                      cursor="pointer"
                      borderTopWidth={index === 0 ? "0" : "1"}
                    >
                      <BusinessTeamMemberInvitation
                        key={invitation.id}
                        invitation={invitation}
                        shouldNavigate={authMemberIsOwnerOrPartner}
                      />
                    </Box>
                  ))}
                </Stack>
              </Box>
            ) : null}
            {authTeamMemberDetails.role.id !== "staff" && totalMembers === 1 ? (
              <Stack
                paddingY="12"
                gap="6"
                alignItems="center"
                justifyContent="center"
              >
                <Stack gap="2">
                  <Heading as="h3" fontSize="md">
                    Add members & Assign Roles
                  </Heading>
                  <Text fontSize="sm" color="gray500">
                    Give access to limited features & books
                  </Text>
                </Stack>
                <Box width="1/2">
                  <img src={AddTeamMemberGuide} alt="Add Team Member" />
                </Box>
              </Stack>
            ) : (
              <>
                <Box>
                  <Text fontWeight="semibold" color="gray500">
                    Owner/Partner
                  </Text>
                  <Stack as="ol" gap="4">
                    {ownerOrPartners.length
                      ? ownerOrPartners.map((teamMember, index) => {
                          const isAuthMember = teamMember.uid === user.uid
                          return (
                            <Box
                              as="li"
                              key={teamMember.uid}
                              paddingY="4"
                              cursor="pointer"
                              borderTopWidth={index === 0 ? "0" : "1"}
                            >
                              <Member
                                isAuthMember={isAuthMember}
                                teamMember={teamMember}
                                shouldNavigate={authMemberIsOwnerOrPartner}
                                isAuthMemberIsStaff={
                                  authTeamMemberDetails.role.id === "staff"
                                }
                              />
                            </Box>
                          )
                        })
                      : null}
                  </Stack>
                </Box>
                {businessStaff.length ? (
                  <Box paddingBottom="12">
                    <Text fontWeight="semibold" color="gray500">
                      Staff({businessStaff.length})
                    </Text>
                    <Stack as="ol" gap="4">
                      {businessStaff.length
                        ? businessStaff.map((teamMember, index) => {
                            const isAuthMember = teamMember.uid === user.uid
                            const books = getBooksForTeamMember(teamMember.uid)
                            return (
                              <Box
                                as="li"
                                key={teamMember.uid}
                                paddingY="4"
                                cursor="pointer"
                                borderTopWidth={index === 0 ? "0" : "1"}
                              >
                                <Member
                                  isAuthMember={isAuthMember}
                                  teamMember={teamMember}
                                  numOfBooksForMember={books.length}
                                  shouldNavigate={authMemberIsOwnerOrPartner}
                                  isAuthMemberIsStaff={
                                    authTeamMemberDetails.role.id === "staff"
                                  }
                                />
                              </Box>
                            )
                          })
                        : null}
                    </Stack>
                  </Box>
                ) : null}
              </>
            )}
          </Stack>
        </Stack>
      </Box>
      {showMemberAddedDialog.phoneNumber?.length ||
      showMemberAddedDialog.email?.length ? (
        <AddedOrInvitedMemberDialog
          role={showMemberAddedDialog.role || "Staff"}
          isInvited={showMemberAddedDialog.isInvited}
          memberCount={`${businessTeam.length + invitations.length}`}
          memberName={
            showMemberAddedDialog.name ||
            showMemberAddedDialog?.email ||
            showMemberAddedDialog?.phoneNumber ||
            "CB User"
          }
          footer={
            <AddedOrInvitedMemberFooter
              isInvited={showMemberAddedDialog.isInvited}
              isPartner={
                showMemberAddedDialog.role &&
                showMemberAddedDialog.role === "partner"
                  ? true
                  : false
              }
              addToBooksClick={() => {
                addToBooksClick({
                  email: showMemberAddedDialog?.email,
                  phoneNumber: showMemberAddedDialog.phoneNumber,
                })
              }}
              onAddMoreMembersClick={() => {
                setShowAddMembersModal(true)
                setShowMemberAddedDialog({ phoneNumber: "" })
              }}
            />
          }
          onClose={() => setShowMemberAddedDialog({ phoneNumber: "" })}
        />
      ) : null}
    </>
  )
}

function AddedOrInvitedMemberFooter({
  isInvited,
  isPartner,
  addToBooksClick,
  onAddMoreMembersClick,
}: {
  isInvited?: boolean
  isPartner?: boolean
  addToBooksClick?: () => void
  onAddMoreMembersClick: () => void
}) {
  return isInvited || isPartner ? (
    <Button onClick={onAddMoreMembersClick} level="primary" size="lg">
      <Box>
        <UserAddIcon />
      </Box>
      Add more members
    </Button>
  ) : (
    <>
      <Button onClick={addToBooksClick} level="primary" size="lg">
        <Box>
          <BookIcon />
        </Box>
        Add to books
      </Button>
      <Button onClick={onAddMoreMembersClick} size="lg">
        <Box>
          <UserAddIcon />
        </Box>
        Add more members
      </Button>
    </>
  )
}

function Member({
  teamMember,
  isAuthMember,
  shouldNavigate,
  numOfBooksForMember,
  isAuthMemberIsStaff,
}: {
  isAuthMember: boolean
  shouldNavigate: boolean
  teamMember: TBusinessMember
  numOfBooksForMember?: number | string
  isAuthMemberIsStaff?: boolean
}) {
  const { name, phoneNumber, id, role, email } = teamMember
  const navigate = useNavigate()
  const shouldNotBeAlignedCenter =
    (email?.length && phoneNumber?.length) ||
    (role.id === "staff" && email?.length)
  return (
    <Inline
      alignItems={!shouldNotBeAlignedCenter ? "center" : undefined}
      gap="4"
      onClick={() => {
        if (shouldNavigate) {
          navigate(id)
        }
      }}
    >
      <MemberAvatar id={id} name={name || phoneNumber} />
      <Stack flex="1" minWidth="0" gap="2">
        <Heading as="h5" fontSize="md" fontWeight="semibold">
          {isAuthMember ? "You" : name}
        </Heading>
        {email?.length ? (
          <Text color="textMedium" fontSize="b3">
            {email}
          </Text>
        ) : null}
        <Inline color="textMedium" fontSize="b3" gap="1">
          <Text>{phoneNumber}</Text>
          {role.id === "staff" &&
          !isAuthMemberIsStaff &&
          phoneNumber?.length ? (
            <Text>•</Text>
          ) : null}
          {role.id === "staff" && !isAuthMemberIsStaff ? (
            <Text>
              {numOfBooksForMember}{" "}
              {pluralize("book", Number(numOfBooksForMember))}
            </Text>
          ) : null}
        </Inline>
      </Stack>
      <BusinessRoleDetails role={role} />
      {shouldNavigate ? (
        <Box alignSelf="center">
          <ArrowRightIcon color="gray500" />
        </Box>
      ) : null}
    </Inline>
  )
}

function BusinessTeamMemberInvitation({
  invitation,
  shouldNavigate,
}: {
  shouldNavigate: boolean
  invitation: TBusinessInvitation
}) {
  const { id, guestName, guestPhone, guestEmail, role, wallet } = invitation
  const invitedUserRole = getBusinessRoleDetails(
    role,
    Boolean(wallet?.can_enable_wallet)
  )
  const navigate = useNavigate()
  const name =
    guestName ||
    guestEmail ||
    parsePhoneNumber(guestPhone)?.nationalNumber ||
    "CB"
  return (
    <Inline alignItems="center" gap="4" onClick={() => navigate(id)}>
      <MemberAvatar id={id} name={name} />
      <Stack flex="1" minWidth="0" gap="2">
        <Heading as="h5" fontSize="md" fontWeight="semibold">
          {name}
        </Heading>
        <Text fontWeight="medium" color="gray500">
          {guestEmail || guestPhone}
        </Text>
        <Box>
          <ResendInvitationInModal invitation={invitation}>
            {({ onResendClick }) => (
              <Button
                inline
                onClick={(e) => {
                  e.stopPropagation()
                  onResendClick()
                }}
              >
                Resend Invitation
              </Button>
            )}
          </ResendInvitationInModal>
        </Box>
      </Stack>
      <BusinessRoleDetails role={invitedUserRole} />
      {shouldNavigate ? <ArrowRightIcon color="gray500" /> : null}
    </Inline>
  )
}
