import { DataLoadingFallback, Box } from "@cashbook/web-components"
import { Navigate } from "react-router-dom"
import { SuspenseWithPerf } from "reactfire"
import ErrorBoundary from "../ErrorBoundary"
import { useBusiness, useBusinesses } from "@cashbook/data-store/businesses"
import { useProfile } from "@cashbook/data-store/users"
import { useUserJourney } from "@cashbook/data-store/payments"

export default function BusinessesListPage() {
  return (
    <Box>
      <SuspenseWithPerf
        fallback={<DataLoadingFallback label="Loading businesses..." />}
        traceId="loading_businesses"
      >
        <ErrorBoundary>
          <RedirectToFirstBusiness />
        </ErrorBoundary>
      </SuspenseWithPerf>
    </Box>
  )
}

export function RedirectToFirstBusiness() {
  const { businesses } = useBusinesses()
  const { user } = useProfile()
  if (!businesses.length) {
    return <Navigate to="/add-first-business" replace />
  } else {
    let businessIdWithPaymentsAccess: string | undefined = undefined
    businesses.forEach((business) => {
      const isCurrentUserOwnerOrPartner =
        user.uid === business.ownerId || business.partners.includes(user.uid)
      if (business.payments?.corpShortName && isCurrentUserOwnerOrPartner) {
        businessIdWithPaymentsAccess = business.id
      }
    })
    if (businessIdWithPaymentsAccess) {
      return (
        <RedirectToPaymentsBusiness businessId={businessIdWithPaymentsAccess} />
      )
    }
    return <Navigate to={`/businesses/${businesses[0].id}/cashbooks`} replace />
  }
}

function RedirectToPaymentsBusiness({ businessId }: { businessId: string }) {
  const { authTeamMemberDetails } = useBusiness(businessId)
  const { isLoading, showPaymentsTab } = useUserJourney(
    businessId,
    authTeamMemberDetails.role.id
  )
  return isLoading ? (
    <Box flex="1" alignItems="center">
      <DataLoadingFallback label="Loading..." />
    </Box>
  ) : (
    <Navigate
      to={`/businesses/${businessId}/${
        showPaymentsTab ? "payments/dashboard" : "cashbooks"
      }`}
      replace
    />
  )
}

export function RedirectToCashbooksOfBusiness() {
  return <Navigate to={`cashbooks`} replace />
}
